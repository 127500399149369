import { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom';

import Dashboard from './Dashboard/Dashboard';
import DataMitra from './DataMitra/DataMitra';
import Permintaan from './PermintaanStok/Permintaan';
import ReturBuang from './PermintaanStok/ReturBuang';
import Diminta from './PengambilanSetoran/Diminta';
import ReturBuangPengambilanSetoran from './PengambilanSetoran/ReturBuang';
import Profile from './Profile/Profile'

const AppKurir = () => {
	return (
		<Fragment>
			<Switch>
				<Route path="/profile" exact component={ Profile } />
				<Route path="/pengambilan-setoran/retur" exact component={ ReturBuangPengambilanSetoran } />
				<Route path="/pengambilan-setoran/diminta" exact component={ Diminta } />
				<Route path="/permintaan-stok/retur" exact component={ ReturBuang } />
				<Route path="/permintaan-stok/permintaan" exact component={ Permintaan } />
				<Route path="/data-mitra" exact component={ DataMitra } />
				<Route path="/" exact component={ Dashboard } />
			</Switch>
		</Fragment>
	)
}

export default AppKurir;