import { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Label, Input } from 'reactstrap';
import { LaporanPenjualanHistory } from '../../../component/History/History';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const LaporanPenjualan = () => {
	let [ state, setState ] = useState({
		historys: [
			{id:0, text:['14 April 2021', 'penjualan retail', 250000, 20]},
			{id:1, text:['10 April 2021', 'penjualan event', 2250000, 250]},
			{id:2, text:['3 April 2021', 'penjualan retail', 590000, 30]},
		],
		formData: {},
		section2style: {}
	})
	let handleData = e => {
		setState({
			...state,
			formData:{
				...state.formData,
				[e.target.name]:e.target.value
			}
		})
	}
	ChangeTitle({title:'Laporan Penjualan'})
	useEffect(()=>{
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setState({
			...state,
			section2style:{padding:'15px 0', margin:'10px 10px', minHeight:String(section2height)+'px'}
		})
		//eslint-disable-next-line
	}, [])
	return(
		<Fragment>
			<div style={{background:'white'}}>
			<section style={{padding:'15px 0 1px', marginBottom:'10px'}}>
				<h4 className="text-center">Laporan Penjualan</h4>
				<hr/>
				<Container>
					<Row>
						<Col xs='12'>
							<p>Periode</p>
						</Col>
						<Col xs='12'>
							<Form inline className='clearfix'>
					            <Input className='float-left' style={{width:'40%'}} bsSize='sm' type="date" name="tanggalStart" id="tanggalStart" onChange={handleData}/>
					            <Label className='float-left' style={{width:'20%', marginTop:'.3em', textAlign:'center', margin:'0 !important'}} for="tanggalEnd">s/d</Label>
					            <Input className='float-left' style={{width:'40%'}} bsSize='sm' type="date" name="tanggalEnd" id="tanggalEnd" onChange={handleData}/>
					        </Form>
					        <hr className='w-100 mt-2'/>
						</Col>
					</Row>
				</Container>
			</section>

			<section style={state.section2style}>
				<Container>
					<div className="pl-3 mt-3 mb-3">
						<h3>Rp. 3.923.902.000</h3>
						<p>Total Penjualan</p>
					</div>
					{state.historys.map(history=>{
						return <LaporanPenjualanHistory key={history.id} text={history.text} />
					})}
				</Container>
				<div className="space-h-5"></div>
			</section>
			</div>
		</Fragment>
	)
}
export default LaporanPenjualan;