const Bars = ({onClick, fill, width}) => {
	let barStyle = {
		position: 'absolute',
		top: '20px',
		right: '20px'
	}
	return(
		<svg style={barStyle} onClick={onClick} width={width} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill={fill} d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
	)
} 

const Home = ({onClick, fill, width}) => {
	return(
		<svg onClick={onClick} width={width} viewBox="0 0 27 29" xmlns="http://www.w3.org/2000/svg">
		<path d="M14.1019 25.7893H18.7208V15.6677H14.1019V25.7893Z" fill={fill}/>
		<path d="M23.332 10.4888L14.1019 2.39999L4.86401 10.4888V24.9459C4.86401 25.4098 5.21101 25.7893 5.63512 25.7893H12.5597V13.9808H20.2553V25.7893H22.5609C22.9927 25.7893 23.332 25.4098 23.332 24.9459V10.4888Z" fill={fill}/>
		</svg>
			)
}

const Profile = ({onClick, fill, width}) => {
	return(
		<svg onClick={onClick} width={width} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill={fill} d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
	)
}

const Search = ({fill,width}) => {
	return(
		<svg aria-hidden="true" width={width} focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill={fill} d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
	)
}
const Filter = ({fill, filterClick}) => {
	return(
		<svg onClick={filterClick} width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<line x1="1" y1="4.33333" x2="32" y2="4.33333" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<line x1="1" y1="14.4444" x2="32" y2="14.4444" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<line x1="1" y1="24.5556" x2="32" y2="24.5556" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<ellipse cx="9.89998" cy="3.88889" rx="3.3" ry="2.88889" fill="white" stroke={fill} strokeWidth="2"/>
		<ellipse cx="9.89998" cy="24.1111" rx="3.3" ry="2.88889" fill="white" stroke={fill} strokeWidth="2"/>
		<ellipse cx="23.1" cy="14" rx="3.3" ry="2.88889" fill="white" stroke={fill} strokeWidth="2"/>
		</svg>
	)
}
const Prev = ({fill}) => {
	return(
		<svg width="14" height="22" viewBox="0 0 14 22" fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M14 2.74977L11.1997 0L2.79961 8.2503L2.79953 8.25022L0 11L2.79953 13.7498L2.79962 13.7497L11.1997 22L14 19.2502L5.59942 11L14 2.74977Z" fill="#009946"/>
		</svg>
	)
}

const Next = ({fill}) => {
	return(
		<svg width="14" height="22" viewBox="0 0 14 22" fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M0 19.2502L2.80032 22L11.2004 13.7497L11.2005 13.7498L14 11L11.2005 8.25022L11.2004 8.25031L2.80032 0L0 2.7498L8.40058 11L0 19.2502Z" fill="#009946"/>
		</svg>
	)
}

const Order = ({fill, onClick}) => {
	return(
		<svg width="10" height="15" viewBox="0 0 10 15" fill={fill} onClick={onClick} xmlns="http://www.w3.org/2000/svg">
			<path d="M4.29019 5.21019C4.38315 5.30391 4.49375 5.37831 4.61561 5.42908C4.73747 5.47985 4.86817 5.50598 5.00019 5.50598C5.1322 5.50598 5.2629 5.47985 5.38476 5.42908C5.50662 5.37831 5.61722 5.30391 5.71019 5.21019L8.71019 2.21019C8.89849 2.02188 9.00428 1.76649 9.00428 1.50019C9.00428 1.23388 8.89849 0.978489 8.71019 0.790185C8.52188 0.601882 8.26649 0.496094 8.00019 0.496094C7.73388 0.496094 7.47849 0.601882 7.29019 0.790185L5.00019 3.09019L2.71019 0.790185C2.52188 0.601882 2.26649 0.496094 2.00019 0.496094C1.73388 0.496094 1.47849 0.601882 1.29019 0.790185C1.10188 0.978489 0.996094 1.23388 0.996094 1.50019C0.996094 1.76649 1.10188 2.02188 1.29019 2.21019L4.29019 5.21019ZM5.71019 9.79019C5.61722 9.69646 5.50662 9.62206 5.38476 9.57129C5.2629 9.52053 5.1322 9.49439 5.00019 9.49439C4.86817 9.49439 4.73747 9.52053 4.61561 9.57129C4.49375 9.62206 4.38315 9.69646 4.29019 9.79019L1.29019 12.7902C1.10188 12.9785 0.996094 13.2339 0.996094 13.5002C0.996094 13.7665 1.10188 14.0219 1.29019 14.2102C1.47849 14.3985 1.73388 14.5043 2.00019 14.5043C2.26649 14.5043 2.52188 14.3985 2.71019 14.2102L5.00019 11.9102L7.29019 14.2102C7.38315 14.3039 7.49375 14.3783 7.61561 14.4291C7.73747 14.4798 7.86817 14.506 8.00019 14.506C8.1322 14.506 8.2629 14.4798 8.38476 14.4291C8.50662 14.3783 8.61722 14.3039 8.71019 14.2102C8.80391 14.1172 8.87831 14.0066 8.92908 13.8848C8.97985 13.7629 9.00598 13.6322 9.00598 13.5002C9.00598 13.3682 8.97985 13.2375 8.92908 13.1156C8.87831 12.9937 8.80391 12.8831 8.71019 12.7902L5.71019 9.79019Z"/>
		</svg>
	)
}

export {
	Bars,
	Home,
	Profile,
	Search,
	Filter,
	Prev,
	Next,
	Order
}