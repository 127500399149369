import { useState, useEffect } from 'react';
import { Container, FormGroup, Label, Row, Col, Input, Table } from 'reactstrap';
import Search from '../../../component/Search/Search';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

let tableData = [
	{mitra:'001', paket:'1', cup:'50', penjualan:'20', sisa:'30'},
	{mitra:'002', paket:'1', cup:'50', penjualan:'20', sisa:'30'},
	{mitra:'003', paket:'1', cup:'50', penjualan:'20', sisa:'30'},
	{mitra:'004', paket:'1', cup:'50', penjualan:'20', sisa:'30'},
	{mitra:'005', paket:'1', cup:'50', penjualan:'20', sisa:'30'},
	{mitra:'006', paket:'1', cup:'50', penjualan:'20', sisa:'30'},
]
const LaporanCup = () => {
	let [ formData, setFormData ] = useState({kodeProduksi:'', periodeStart:null, periodeEnd:null})
	let [ section2style, setSection2style ] = useState({})

	let handleForm = e => {
		setFormData({
			[e.target.name]:e.target.value
		})
	}

	ChangeTitle({title:'Laporan Cup'})
	useEffect(()=>{
		console.log(formData)
	}, [formData])
	//filler bawah
	useEffect(()=>{
		let section2height = window.innerHeight-document.getElementById('laporanCupId').getBoundingClientRect().top
		setSection2style({minHeight:String(section2height)+'px'})
	}, [])

	let statusBox = {background:'var(--green)', textAlign:'center', color:'var(--abu-abu)', borderRadius:'1em', padding:'.25em'}
	return (
		<div style={{background:'white'}}>
			<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Laporan Cup Mitra</h1>
			<Container>
				<FormGroup row>
					<Label style={{fontSize:'.9em'}} xs={3} to="kodeProduksi" className="text-right p-0">Pilih Kode Produksi</Label>
					<Col xs={9}>
						<Input bsSize='sm' id="kodeProduksi" name="kodeProduksi" type="text" onChange={handleForm}/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label style={{fontSize:'.9em'}} xs={3} to="periodeStart" className="text-right p-0">Periode</Label>
					<Col xs={4}>
						<Input bsSize='sm' id="periodeStart" name="periodeStart" type="date" onChange={handleForm}/>
					</Col>
					<Col xs={1} style={{marginTop:'.2em', textAlign:'center', padding:'0'}}><p>s/d</p></Col>
					<Col xs={4}>
						<Input bsSize='sm' id="periodeEnd" name="periodeEnd" type="date" onChange={handleForm}/>
					</Col>
				</FormGroup>
			</Container>
			<Container>
				<hr style={{margin:'1em 0', width:'100%'}}/>
					<Row>
						<Col>
							<div style={statusBox}>
								<p>Total Cup</p>
								<h3>30</h3>
							</div>
						</Col>
						<Col>
							<div style={statusBox}>
								<p>Total Cup</p>
								<h3>30</h3>
							</div>
						</Col>
						<Col>
							<div style={statusBox}>
								<p>Total Cup</p>
								<h3>30</h3>
							</div>
						</Col>
					</Row>
				<hr style={{margin:'1em 0', width:'100%'}}/>
			</Container>

			<Container id="laporanCupId" style={section2style}>
				<Search />
				<div>
					<Table>
				      <thead>
				        <tr>
				          <th>Mitra</th>
				          <th>Paket</th>
				          <th>Cup</th>
				          <th>Penjualan</th>
				          <th>Sisa</th>
				        </tr>
				      </thead>
				      <tbody>
				      	{tableData.map(table=>{
				      		return (
						        <tr key={table.mitra}>
						          <th scope="row">{table.mitra}</th>
						          <td>{table.paket}</td>
						          <td>{table.cup}</td>
						          <td>{table.penjualan}</td>
						          <td>{table.sisa}</td>
						        </tr>
				      		)
				      	})}
				      </tbody>
				    </Table>
				</div>
			</Container>

			<div className="space"></div>
		</div>
	)
}

export default LaporanCup;