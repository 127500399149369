import {Component, Fragment} from 'react';
import { Route, Switch } from 'react-router-dom';

// === Pages
// = Etc
import YukBacaSholawat from './YukBacaSholawat/YukBacaSholawat';

// = Dashboard
import Dashboard from './Dashboard/Dashboard';

import Profile from './Profile/Profile';

// = Menu Section 1
import PenjualanHarian from './PenjualanHarian/PenjualanHarian';
import TambahPenjualan from './PenjualanHarian/TambahPenjualan';
import StokPermintaan from './StokPermintaan/StokPermintaan';
import StokPenerimaan from './StokPenerimaan/StokPenerimaan';
import StokFormTerima from './StokPenerimaan/StokFormTerima';
import StokReturBuang from './StokReturBuang/StokReturBuang';

// = Menu Section 2
import SetorWilayah from './SetorWilayah/SetorWilayah';
import DataCustomer from './DataCustomer/DataCustomer';
import LaporanStok from './LaporanStok/LaporanStok';
import LaporanPenjualan from './LaporanPenjualan/LaporanPenjualan';
import LaporanKeuangan from './LaporanKeuangan/LaporanKeuangan';

class AppMitra extends Component {
	constructor(props){
		super(props)
		this.state = {}
	}
	render(){
		return(
			<Fragment>

					{/* ==== Switch - Route ==== */}
					<Switch>
						{/* == Etc == */}
						<Route path="/yuk-baca-sholawat">
							<YukBacaSholawat />
						</Route>

						{/* == Menu Section 2 == */}
						<Route path="/laporan-keuangan">
							<LaporanKeuangan />
						</Route>
						<Route path="/laporan-penjualan">
							<LaporanPenjualan />
						</Route>
						<Route path="/laporan-stok">
							<LaporanStok />
						</Route>
						<Route path="/data-customer">
							<DataCustomer />
						</Route>
						<Route path="/setor-wilayah">
							<SetorWilayah />
						</Route>

						{/* == Menu Section 1 == */}
		          		<Route path="/stok/permintaan" exact component={ StokPermintaan } />
		          		<Route path="/stok/penerimaan" exact component={ StokPenerimaan } />
			          	<Route path="/stok/penerimaan/terima" exact component={ StokFormTerima } />
		          		<Route path="/stok/retur" exact component={StokReturBuang} />
		          		<Route path="/penjualan" exact component={PenjualanHarian} />
		          		<Route path="/penjualan/tambah-penjualan" exact component={ TambahPenjualan } />

		          		{/* == Nav Bawah == */}
			          	<Route path="/profile" exact component={ Profile } />
			          	<Route path="/" exact>
			          		<Dashboard />
			          	</Route>
		        	</Switch>

			</Fragment>
		)
	}
}

export default AppMitra;