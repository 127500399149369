import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import History  from './History';
import PeriodeForm from '../../../component/PeriodeForm/PeriodeForm';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const historys = [
			{id:0, text:['14 April 2021', 'no. Setor 2313', 'Rp, 250.000', 'kode mitra', true]},
			{id:1, text:['30 Maret 2021', 'no. Setor 2312', 'Rp, 3.250.000', 'kode mitra', true]},
			{id:2, text:['15 Maret 2021', 'no. Setor 2311', 'Rp, 25.642.000', 'kode mitra', true]},
			{id:3, text:['10 Maret 2021', 'no. Setor 2309', 'Rp, 25.452.100', 'kode mitra', true]},
		]

const Diterima = () => {
	let [ section2style, setSection2style ] = useState({})
	let boxStyle = {borderRadius:'1.2em', boxShadow:'0px 4px 4px 0px #00000040 inset', paddingTop:'.4em'}

	ChangeTitle({title:'Setoran Penjualan Wilayah | Diterima'})
	useEffect(()=>{
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2style({minHeight:String(section2height)+'px'})
	}, [])
	return (
		<div  style={{background:'#fff',padding:'15px 0'}}>
			<section>
					<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Setoran Penjualan Wilayah</h1>
					<hr style={{margin:'0 auto',width:'86%'}} />
					<Container className="text-center mt-2">
						<Row className="ml-2 mr-2 stok-nav">
							<Col xs="6 p-0">
								<Link to="/terima-uang-penjualan/proses">Proses</Link>
								<hr/>
							</Col>
							<Col xs="6 p-0">
								<Link to="/terima-uang-penjualan/diterima">Diterima</Link>
								<hr className="active"/>
							</Col>
						</Row>
						<PeriodeForm className='clearfix my-2 mx-auto' />
					</Container>
				</section>

				<Container>
					<Row className="text-center mt-3">
						<Col xs='12' className="mb-2">
							<div style={boxStyle}>
								<h5>Rp 3.091.231.123</h5>
								<p>Total Penerimaan Setoran</p>
							</div>
						</Col>
						<Col xs="6" className="pr-1">
							<div style={boxStyle}>
								<h5>Rp 3.091.231.123</h5>
								<p>Total Hak Wilayah</p>
							</div>
						</Col>
						<Col xs="6" className="pl-1">
							<div style={boxStyle}>
								<h5>Rp 3.091.231.123</h5>
								<p>Total Hak Pusat</p>
							</div>
						</Col>
					</Row>
				</Container>

				<section style={section2style}>
					<Container>
						<p className="ml-3 mt-4"><b>Riwayat Setoran</b></p>
						<hr style={{width:'96%', borderTop:'.25px solid grey', margin:'10px'}}/>
						{historys.map(history=>{
							return <History key={history.id} text={history.text} />
						})}
					</Container>
				</section>
		</div>
	)
}
export default Diterima;