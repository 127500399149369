import { useState, useEffect } from 'react';
import { Input, Table } from 'reactstrap';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

let tableList = [
	{name:'Dawet'},
	{name:'Creamer'},
	{name:'Gula'},
	{name:'CupDanTutup'},
	{name:'Sendok'},
	{name:'Plastik1'},
	{name:'Plastik2'},
	{name:'PlastikBesar'},
	{name:'Sedotan'},
	{name:'Brosur'},
	{name:'Es'},
]
const SettingPaketAcara = () => {
	let [ formData, setFormData ] = useState({
		Dawet:{'1':10,'1per2':20},
		Creamer:{'1':10,'1per2':20},
		Gula:{'1':10,'1per2':20},
		CupDanTutup:{'1':10,'1per2':20},
		Sendok:{'1':10,'1per2':20},
		Plastik1:{'1':10,'1per2':20},
		Plastik2:{'1':10,'1per2':20},
		PlastikBesar:{'1':10,'1per2':20},
		Sedotan:{'1':10,'1per2':20},
		Brosur:{'1':10,'1per2':20},
		Es:{'1':10,'1per2':20},
	});
	let handleData = (e) => {
		setFormData({
			...formData,
			[e.target.name]:{
				...formData[e.target.name],
				[e.target.dataset.col]:e.target.value
			}
		})
	}
	let handleSubmit = e => {
		console.log(formData)
	}
	ChangeTitle({title:'Setting Paket Retail'})
	useEffect(()=>{
		console.log(formData)
	}, [formData])
	let inputStyle = {background:'white',borderRadius:'.5em',padding:'.5em',textAlign:'center'}
	return (
		<div style={{background:'var(--abu-abu0)'}}>
			<section style={{padding:'15px 0'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Setting Komponen Paket Retail</h1>
				<hr style={{width:'90%', margin:'0 auto'}} />
			</section>

			<section className="mx-4" id="fillProfileKurir">
				<Table className='mt-4'>
						<thead className='text-center'>
					        <tr>
					          	<th></th>
					         	<th>Paket 1</th>
					         	<th>Paker 1/2</th>
					        </tr>
					    </thead>
					    <tbody>
					      	{tableList.map(tl=>{
					      		return <tr key={tl.name}>
						          <th scope="row"><div style={{background:'white',borderRadius:'.5em',padding:'.5em'}}>{tl.name}</div></th>
						          <th>
						          	<Input type='number' name={tl.name} data-col='1' value={formData[tl.name]['1']} style={inputStyle} onChange={handleData}/>
						          </th>
						          <th>
						          	<Input type='number' name={tl.name} data-col='1per2' value={formData[tl.name]['1per2']} style={inputStyle} onChange={handleData}/>
						          </th>
						        </tr>
					      	})}
					    </tbody>
				</Table>
				<button className="btn btn-success w-100 mt-4" onClick={handleSubmit} name='simpanProfile'>SIMPAN</button>
			</section>

			<div className="space"></div>
		</div>
	)
}

export default SettingPaketAcara;