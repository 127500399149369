import {Component} from 'react'
import {Container, Row, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import Popup from '../../../component/Popup/Popup';

export default class MintaStok extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData:{
				noPermintaanStok: null,
				tanggal: null,
				paket : null,
				kategori : null
			}
		}
	}
	closePopup = () => {
		this.props.close()
	}
	handleData = e => {
		let keys = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[keys]: value
			}
		})
	}
	handleSubmit = (e) => {
		e.preventDefault()
		this.props.close('ok')
	}
	render(){
		return(
			<Popup close={this.closePopup}>
				<h4 className="text-center">MINTA STOK</h4>
				<Form>
					<Container>
						<FormGroup>
							<Label for="kategori">Kategori</Label>
					        <Input type="select" name="kategori" id="kategori" onChange={this.handleData} >
					          	<option value={null}>Stok Retail</option>
					          	<option value="Stok Retail">Stok Retail</option>
					          	<option value="Stok Online">Stok Online</option>
				          	</Input>
						</FormGroup>
						<Row>
							<Col xs="6">
								<Label for="tanggal">Tanggal</Label>
								<Input id="tanggal" name="tanggal" type="date" onChange={this.handleData}/>
							</Col>
							<Col xs="6">
								<Label for="paket">Paket</Label>
								<Input id="paket" name="paket" type="select" onChange={this.handleData}>
						          	<option value='1'>1</option>
						          	<option value='1/2'>1/2</option>
						          	<option value='1/4'>1/4</option>
								</Input>
							</Col>
						</Row>
						<FormGroup>
							<Label for="jumlahPcs">Jumlah pcs</Label>
							<Input id="jumlahPcs" name="jumlahPcs" type="number" onChange={this.handleData}/>
						</FormGroup>
						<button onClick={this.handleSubmit} className="btn btn-block btn-success mt-4">MINTA STOK</button>
					</Container>
				</Form>
			</Popup>
		)
	}
}