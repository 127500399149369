import {Component} from 'react';

export default class YukBacaSholawat extends Component{
	state = {
		sholawatCount: 0
	}
	handleHitung = () => {
		if(this.state.sholawatCount!==100){
			this.setState({
				sholawatCount: this.state.sholawatCount+1
			})
		}
	}
	render(){
		return(
			<div style={{width:'100%', background:'#fff', position:'absolute', top:'0', bottom:'0'}}>
				<div style={{background:'green', margin:'15px', height:'100%', borderRadius:'30px', textAlign:'center', padding:'150px 0', color:'#fff'}}>
					<h4 style={{fontFamily:"Aladin"}}>yuk baca sholawat 100x</h4>
					<h1 className="display-1" style={{margin:'80px 0'}}>{this.state.sholawatCount}</h1>
					<button style={{padding:'30px 50px', border:'none', borderRadius:'20px'}} onClick={this.handleHitung}>HITUNG</button>
				</div>
			</div>
		)
	}
}