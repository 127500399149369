import { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

let listMitra = [
	{name:'Nama Mitra', address:'Jalan Sukasuli No. 6, Bandung', distance:'1km', phone:'8012312'},
	{name:'Nama Mitra2', address:'Jalan Sukasuli No. 7, Jakarta', distance:'10km', phone:'8012313'},
	{name:'Nama Mitra3', address:'Jalan Sukasuli No. 8, Surabaya', distance:'100km', phone:'8012314'},
	{name:'Nama Mitra4', address:'Jalan Sukasuli No. 9, London', distance:'1000km', phone:'8012315'},
]
const SearchMitra = () => {
	let [ fillStyle, setFillStyle ] = useState({})
	ChangeTitle({title:'Cari Penjual'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setFillStyle({background:'#fff', padding:'1.5em 0', borderRadius:'25px 25px 0 0', height:'100%',minHeight:String(section2height)+'px'})
	}, [])
	return (
		<Fragment>
			<section style={{background:'#fff', padding:'15px 0 1px', borderRadius:'0 0 25px 25px', marginBottom:'10px'}}>
				<h4 className="text-center m-0">Cari Penjual</h4>
				<hr style={{margin:'1em 1.5em'}}/>
			</section>

			<button className="btn btn-success btn-block" style={{width:'80%', padding: '10px', margin:'1em auto'}}>Akses Lokasi</button>

			<section style={fillStyle}>
				{listMitra.map(mitra=>{
					return <MitraList key={mitra.phone} name={mitra.name} addressText={mitra.address} distance={mitra.distance} phone={mitra.phone} />
				})}
			</section>

			<div className="space"></div>
		</Fragment>
	)
}

const MitraList = ({ name, addressText, distance, phone }) => {
	let style = {background:'var(--abu-abu2)', width:'80%', height:'100%', borderRadius:'1em', margin:'0 auto'}
	return (
		<Container>
			<Row>
				<Col xs='4'>
					<div style={style}></div>
				</Col>
				<Col xs="8" className="pl-0">
					<p><b>{name}</b></p>
					<p className="text-muted">{addressText}</p>
					<Row>
						<Col>{distance}</Col>
						<Col><Link to={'/'+phone} className="btn btn-success btn-sm d-block" style={{width:'100%', borderRadius:'2em'}}>
							<div className="clearfix m-auto w-fit-content">
								<img className='float-left' src="/img/pembeli/Whatsapp.svg" alt="Whatsapp.svg" style={{margin:'.2em'}} />
								<p className='float-left' >hubungi</p>
							</div>
						</Link></Col>
					</Row>
				</Col>
			</Row>
			<hr className="my-2"/>
		</Container>
	)
}

export default SearchMitra;