import {Search as SearchSVG, Filter} from '../ImageSvg/ImageSvg';
import {Row, Col} from 'reactstrap';
import './Search.css';

const Search = ({onChange, name, placeholder}) => {
	return(
		<div >
			<Row className="search-wrapper">		
				<Col>
					<div className="search">
						<SearchSVG width='20' fill="#bbb"/>
						<input type="text" placeholder={placeholder||"Search"} onChange={onChange} name={name}/>
					</div>
				</Col>
			</Row>
		</div>
	)
}
export default Search;

export const SearchWithFilter = ({filterClick}) => {
	return(
		<div >
			<Row className="search-wrapper">		
				<Col xs='10'>
					<div className="search">
						<SearchSVG width='20' fill="#bbb"/>
						<input type="text" placeholder="Search"/>
					</div>
				</Col>
				<Col xs='1'>
						<Filter fill="#bbb" filterClick={filterClick}/>
				</Col>
			</Row>
		</div>
	)
}