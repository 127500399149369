import {Container, Row, Col} from 'reactstrap';
import MenuList from '../../../component/MenuList/MenuList';
import PeriodeForm from '../../../component/PeriodeForm/PeriodeForm';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

let menuList = [
	{id:0, text:'Terima Uang Penjualan', link:"/terima-uang-penjualan/diterima"},
	{id:1, text:'Kirim Uang Penjualan', link:'/kirim-uang-penjualan'},
	{id:2, text:'Keuangan Wilayah', link:'/keuangan-wilayah/uang-masuk'},
	{id:3, text:'Permintaan Stok', link:'/permintaan-stok/permintaan'},

	{id:4, text:'Laporan Cup', link:'/laporan-cup'},
	{id:5, text:'Laporan Penjualan', link:'/laporan-penjualan'},
	{id:6, text:'Data Kurir', link:'/data-kurir'},
	{id:7, text:'Data Mitra', link:'/data-mitra'}
]

const Dashboard = () => {
	ChangeTitle({title:'Dashboard'})
	return(
		<main>
			<section style={{ background:'#fff', padding:'.2em 0 1em', borderRadius:'0 0 25px 25px', marginBottom:'10px'}}>
				<Container>
					<PeriodeForm />
					<hr/>
					<Row>
						<Col>
							<h3 className="mb-0 text-blue">Rp 65.000.000.000</h3>
							<p>omzet penjualan</p>
						</Col>
					</Row>
					<Row className="text-center">
						<Col xs={{size:4, offset:4}}>
								<h3 className="text-blue mb-0">300</h3>
								<p>Stok terjual</p>
						</Col>
						<Col xs="4">
								<h3 className="text-blue mb-0">200</h3>
								<p>Stok sisa</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section  className='sec'>
				<Container>
					<Row>
						{menuList.map(menu=>{
							return <MenuList key={menu.id} link={menu.link} text={menu.text} />
						})}
					</Row>
				</Container>
			</section>
		</main>
	)
}

export default Dashboard;