import { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const Profile = () => {
	let [ formData, setFormData ] = useState({ nama:'', nomor:'', hp:'', alamat:'', password:'' });
	let handleData = (e) => {
		setFormData({
			[e.target.name]:e.target.value
		})
	}
	let handleSubmit = e => {
		console.log(formData)
	}
	ChangeTitle({title:'Profile'})
	return (
		<div style={{background:'var(--abu-abu0)'}}>
			<section style={{padding:'15px 0'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>PROFILE KURIR</h1>
				<hr style={{width:'90%', margin:'0 auto'}} />
			</section>

			<section className="mx-4" id="fillProfileKurir">
				<FormGroup>
					<Label for="nama">Nama Kurir</Label>
					<Input type="text" name="nama" id="nama" onChange={handleData} placeholder="nama lengkap" />
				</FormGroup>
				<FormGroup>
					<Label for="hp">Nomor WA/Handphone</Label>
					<Input type="number" name="hp" id="hp" onChange={handleData} placeholder="nomor kontak" />
				</FormGroup>
				<FormGroup>
					<Label for="alamat">Alamat</Label>
					<Input type="date" name="alamat" id="alamat" onChange={handleData} placeholder="alamat" />
				</FormGroup>
				<FormGroup>
					<Label for="password">Password</Label>
					<Input type="password" name="password" id="password" onChange={handleData} placeholder="password" />
				</FormGroup>
				<button className="btn btn-success w-100 mt-4" onClick={handleSubmit} name='simpanProfile'>SIMPAN PROFILE</button>
				<button className="btn btn-danger w-100 mt-2" onClick={handleSubmit} name='logOut'>LOG OUT</button>
			</section>

			<div className="space"></div>
			<div className="space"></div>
		</div>
	)
}

export default Profile;