import { useState } from 'react';
import { FormGroup, Label, Input, Button, Col } from 'reactstrap';
import Popup from '../../../component/Popup/Popup';

const TambahProduk = ({close}) => {
	let [ formData, setFormData ] = useState({tanggal:null, kodeProduksi:'', jumlahPaket:0})
	let handleForm = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}
	let submit = e => {
		e.preventDefault()
		close(formData)
	}
	return (
		<Popup close={close}>
			<div style={{padding:'1em'}}>
				<h5 className="text-center">TAMBAH PRODUKSI</h5>
				<FormGroup row>
					<Col xs='6'>
						<Label to="tanggal">Tanggal</Label>
						<Input id="tanggal" name="tanggal" type="date" onChange={handleForm}/>
					</Col>
					<Col xs='6'>
						<Label to="kodeProduksi">Kode Produksi</Label>
						<Input id="kodeProduksi" name="kodeProduksi" type="text" onChange={handleForm}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Label to="jumlahPaket">Jumlah Paket</Label>
					<Input id="jumlahPaket" name="jumlahPaket" type="number" onChange={handleForm}/>
				</FormGroup>
				<Button color="success" block onClick={submit}>TAMBAH PRODUKSI</Button>
			</div>
		</Popup>
	)
}

export default TambahProduk;