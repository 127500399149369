import { useState, useEffect } from 'react'
import { Container, Row, Col, FormGroup, Label, Input, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const StokFormTerima = props => {
	let [ state, setState ] = useState({ formData:{} })
	let handleData = e => {
		let keys = e.target.name
		let value = e.target.value
		setState({
			...state,
			formData:{
				...state.formData,
				[keys]: value
			}
		})
	}
	let handleSubmit = e => {
		e.preventDefault()
		if(e.target.name==='close'){
			props.history.goBack()
		}
	}
	ChangeTitle({title:'Paket Stok | Penerimaan | Form Terima'})
	useEffect(()=>{
		document.getElementsByTagName('nav')[0].style.display = 'none'
		return ()=>{
			document.getElementsByTagName('nav')[0].style.display = 'block'
		}
	})

	return(
		<div style={{background:'var(--abu-abu0)'}}>
			<h4 className="text-center">TERIMA STOK</h4>
				<Container>
					<FormGroup>
						<Row>
							<Col className="pr-1">
								<Label for="tanggal">Tanggal</Label>
								<Input id="tanggal" name="tanggal" type="date" onChange={handleData}/>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup>
						<Row>
							<Col xs="6">
								<Label for="kurir">Kurir</Label>
								<Input id="kurir" name="kurir" type="text" onChange={handleData}/>
							</Col>
							<Col xs="6">
								<Label for="kodeProduksi">Kode Produksi</Label>
								<Input id="kodeProduksi" name="kodeProduksi" type="text" onChange={handleData}/>
							</Col>
						</Row>
					</FormGroup>
					<p className="text-center mt-3">Kondisi Paket</p>
					{
						[['Dawet','liter'],['Creamer','pack'],['Gula','kilo'],['Cup+tutup','pack'],['Sendok','pack'],['Plastik 1','Pack'],['Plastik 2','Pack'],['Plastik Besar','Pack'],['Sedotan','Pack'],['Brosur','lmbar'],['Es','kilo']].map(props=>{
							return <FormTambahan key={props[0]} name={props[0]} satuan={props[1]} onChange={handleData} />
						})
					}
					<button onClick={event=>handleSubmit(event)} name="terima" className="btn btn-block btn-success mt-4">TERIMA</button>
					<button onClick={event=>handleSubmit(event)} name="close" className="btn btn-block btn-danger mt-2">CLOSE</button>
				</Container>
			<div className='space'></div>
		</div>
	)
}
export default StokFormTerima;

const FormTambahan = ({name, satuan, onChange}) => {
	return(
		<FormGroup>
			<Row>
				<Col xs="3" className="mt-4">
					<p>{name}</p>
				</Col>
				<Col xs="3 pr-1">
					<Label for={name+"Datang"} className="mb-0">Datang</Label>
					<InputGroup>
						<Input id={name+"Datang"} name={name+"Datang"} type="number" onChange={onChange}/>
						<InputGroupAddon addonType="append">
				        	<InputGroupText className="p-0">{satuan}</InputGroupText>
				        </InputGroupAddon>
			        </InputGroup>
				</Col>
				<Col xs="3 pl-1 pr-1">
					<Label for={name+"Diterima"} className="mb-0">Diterima</Label>
					<InputGroup>
						<Input id={name+"Diterima"} name={name+"Diterima"} type="number" onChange={onChange}/>
						<InputGroupAddon addonType="append">
				        	<InputGroupText className="p-0">{satuan}</InputGroupText>
				        </InputGroupAddon>
				    </InputGroup>
				</Col>
				<Col xs="3 pl-1">
					<Label for={name+"Reject"} className="mb-0">Reject</Label>
					<InputGroup>
						<Input id={name+"Reject"} name={name+"Reject"} type="number" onChange={onChange}/>
						<InputGroupAddon addonType="append">
				        	<InputGroupText className="p-0">{satuan}</InputGroupText>
				        </InputGroupAddon>
				    </InputGroup>
				</Col>
				<hr/>
			</Row>
		</FormGroup>
	)
}