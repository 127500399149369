import { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link} from 'react-router-dom';

import { StokPermintaanHistory } from '../../../component/History/History';
import { Filter as FilterSvg } from '../../../component/ImageSvg/ImageSvg';
import Alert from '../../../component/Alert/Alert';
import MintaStok from './MintaStok';
import Filter from './Filter';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const StokPermintaan = () => {
	let [ state, setState ] = useState({
		isActiveMintaStok: false,
		isActiveFilter: false,
		isActiveAlert: false,
		historys:[
			{ id:0, text:["No. 12345","stok retail","14 April 2021","Diminta","10 paket"], cssClassStatus:"text-primary" },
			{ id:1, text:["No. 12344","stok retail","05 April 2021","Ditolak","3 paket"], cssClassStatus:"text-danger" },
			{ id:2, text:["No. 12343","stok retail","01 April 2021","Diterima","129 paket"], cssClassStatus:"text-success" },
		],
		section2style: {background:'#fff',padding:'15px 0',borderRadius:'25px 25px 0 0', height:''}
	})
	let alertToggle = () => {
		setState({
			...state,
			isActiveAlert: !state.isActiveAlert
		})
	}
	let tambahStokHandle = data => {
		if(data==="ok"){
			setState({
				...state,
				isActiveMintaStok: !state.isActiveMintaStok,
				isActiveAlert: !state.isActiveAlert
			})
		}else{
			setState({
				...state,
				isActiveMintaStok: !state.isActiveMintaStok,
			})
		}
	}
	let filterHandle = data => {
		setState({
			...state,
			isActiveFilter: !state.isActiveFilter
		})
	}
	ChangeTitle({title:'Paket Stok | Permintaan'})
	useEffect(()=>{
		let space = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top+'px';
		setState({
			...state,
			section2style:{background:'#fff',padding:'15px 0',borderRadius:'25px 25px 0 0', height:space}
		})
		//eslint-disable-next-line
	}, [])
	return(
	<Fragment>
		{state.isActiveAlert?<Alert text="selamat info stok anda telah masuk mohon menunggu untuk dikonfirmasi wilayah" close={alertToggle} />:null}
		{state.isActiveMintaStok?<MintaStok close={data=>tambahStokHandle(data)}/>:null} 
		{state.isActiveFilter?<Filter close={data=>filterHandle(data)} />:null}

		<section style={{background:'#fff',padding:'15px 0',borderRadius:'0 0 25px 25px',marginBottom:'10px'}}>
			<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Paket Stok</h1>
			<hr style={{margin:'0 auto',width:'86%'}} />
			<Container className="text-center mt-2">
				<Row className="ml-2 mr-2 stok-nav">
					<Col xs="4 p-0">
						<Link to="/stok/permintaan">Permintaan</Link>
						<hr className="active"/>
					</Col>
					<Col xs="4 p-0">
						<Link to="/stok/penerimaan">Penerimaan</Link>
						<hr/>
					</Col>
					<Col xs="4 p-0">
						<Link to="/stok/retur">Retur.Buang</Link>
						<hr/>
					</Col>
				</Row>
			</Container>
		</section>

		<section style={state.section2style}>
			<Container>
				<div className="text-right mb-3">
					<FilterSvg fill="black" filterClick={filterHandle} /> 
				</div>
				<hr className="my-1 w-100"/>
				<Row>
					<Col xs="6">
						<strong>Stok Terkini</strong>
						<p className="text-muted">kode: 10/5/2021 - SMD</p>
					</Col>
					<Col xs="6">
						<button onClick={tambahStokHandle} className="btn btn-success btn-block p-2">MINTA STOK</button>
					</Col>
				</Row>
			</Container>
			<p className="text-center mb-1 mt-3"><strong>Riwayat Permintaan</strong></p>
			<hr className="mt-0"/>
			<Container>
				{state.historys.map(history=>{
					return <StokPermintaanHistory key={history.id} text={history.text} cssClassStatus={history.cssClassStatus} />
				})}
			</Container>
		</section>
	</Fragment>
	)
}
export default StokPermintaan;