import React from 'react';
import { Container, Form, Label, Input } from 'reactstrap';

const PeriodeForm = ({onChange, className}) => {
  return (
    <Container fluid>
      <div>
        <Form inline className={className||'my-2 clearfix col-sm-8'}>
            <Label className='float-left' style={{width:'20%', marginTop:'.3em', textAlign:'center', margin:'0 !important'}} for="tanggalStart">Periode</Label>
            <Input className='float-left' style={{width:'35%'}} bsSize='sm' type="date" name="tanggalStart" id="tanggalStart" onChange={onChange}/>
            <Label className='float-left' style={{width:'10%', marginTop:'.3em', textAlign:'center', margin:'0 !important'}} for="tanggalEnd">s/d</Label>
            <Input className='float-left' style={{width:'35%'}} bsSize='sm' type="date" name="tanggalEnd" id="tanggalEnd" onChange={onChange} />
        </Form>
      </div>
    </Container>
  );
}

export default PeriodeForm;