import {Fragment, useState} from 'react';
import {Prev, Next} from '../ImageSvg/ImageSvg';
import {Container, Col} from 'reactstrap';

const DasboardPusatPagination = ({texts}) => {
	let [textsMap, setTextsMap] = useState([texts[0],texts[1]])
	function handleNextClick(){
		if( texts.indexOf(textsMap[1]) !== texts.length-1 ){
			setTextsMap([ texts[texts.indexOf(textsMap[0])+1], texts[texts.indexOf(textsMap[1])+1] ])
		}
	}
	function handlePrevClick(){
		if( texts.indexOf(textsMap[0]) !== 0 ){
			setTextsMap([ texts[texts.indexOf(textsMap[0])-1], texts[texts.indexOf(textsMap[1])-1] ])
		}
	}
	console.log()
	return(
		<Fragment>
			<div>
				<div style={{float:'left', width:'15%'}} onClick={handlePrevClick}><p className="mb-0"><Prev fill="#009946" /> prev</p></div>
				<div style={{float:'left', width:'70%', textAlign:'center', fontWeight:'600'}}><p className="mb-0"><b>Data Wilayah</b></p></div>
				<div style={{float:'left', width:'15%', textAlign:'right'}} onClick={handleNextClick}><p className="mb-0">next <Next fill="#009946" /></p></div>
				<div style={{clear:'both'}}></div>
			</div>
			<Container>
				<div>
					{textsMap.map(text=>{
						return(
							<Col xs="6" className="pl-1 pr-1" style={{float:'left'}} key={text[0]}>
								<div style={{padding: '10px 20px', borderRadius:'20px', background: '#fff'}}>
									<p className="text-center">{text[0]}</p>
									<p className="mb-0" style={{fontSize: '.8em'}}>omzet</p>
									<p className="text-blue mb-1" style={{fontWeight:'700'}}>Rp. {text[1]}</p>
									<div className="ml-3">
										<p className="mb-0 text-blue" style={{fontSize:'.8em'}}><span className="h5">{text[2]}</span> Stok terjual</p>
										<p className="mb-2 text-blue" style={{fontSize:'.8em'}}><span className="h5">{text[3]}</span> Stok sisa</p>
									</div>
								</div>
							</Col>
						)
					})}
					<div style={{clear:'both'}}></div>
				</div>
			</Container>
		</Fragment>		
	)
}

export default DasboardPusatPagination;