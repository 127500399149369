import { Fragment, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Filter as FilterSvg } from '../../../component/ImageSvg/ImageSvg';
import Filter from './Filter';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const listPenjualan = [
	{id:0, date:'15 April 2021', group:'penjualan retail', balance:250000, count:25},
	{id:1, date:'16 April 2021', group:'penjualan retail', balance:500000, count:50},
	{id:2, date:'17 April 2021', group:'penjualan retail', balance:750000, count:75},
	{id:3, date:'18 April 2021', group:'penjualan retail', balance:1000000, count:100},
	{id:4, date:'16 Mei 2021', group:'penjualan event', balance:500000, count:50},
	{id:5, date:'17 Mei 2021', group:'penjualan event', balance:750000, count:75},
	{id:6, date:'18 Mei 2021', group:'penjualan event', balance:1000000, count:100},
	{id:7, date:'18 Agustus 2021', group:'penjualan berbagi', balance:1000000, count:100},
	{id:8, date:'16 Agustus 2021', group:'penjualan berbagi', balance:5000000, count:500},
	{id:9, date:'17 Agustus 2021', group:'penjualan berbagi', balance:7500000, count:750},
	{id:10, date:'18 Agustus 2021', group:'penjualan berbagi', balance:10000000, count:1000},
]

const PenjualanHarian = () => {
	let [ state, setState ] = useState({
		isActiveFilter: false
	})
	let filterHandle = () => {
		setState({
			isActiveFilter: !state.isActiveFilter
		})
	}
	ChangeTitle({title:'Penjualan'})
	return(
		<Fragment>
		
			{state.isActiveFilter?<Filter close={filterHandle} />:null}
			
			<div style={{background:'#fff'}}>
				<section style={{padding:'15px 0'}}>
					<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Penjualan</h1>
					<hr style={{width:'90%', margin:'0 auto'}} />
				</section>

				<Link to="/penjualan/tambah-penjualan" style={{width:'80%',margin:'15px auto',padding:'10px 0',display:'block'}} className="btn btn-success">TAMBAH PENJUALAN</Link>

				<section>
					<Container>
						<Row style={{borderRadius:'15px', margin:'0 5px'}}>
							<Col xs="9">
								<h3>Rp 3.912.029.000</h3>
								<p>Total Penjualan</p>
							</Col>
							<Col xs="3">
								<FilterSvg fill="black" filterClick={filterHandle} />
							</Col>
						</Row>
						{listPenjualan.map(penjualan=>{
							return <PenjualanHarianHistory key={penjualan.id} date={penjualan.date} group={penjualan.group} balance={penjualan.balance} count={penjualan.count} />
						})}
						<div className="space"></div>
					</Container>
				</section>
			</div>

		</Fragment>	
	)
}

export default PenjualanHarian;


const PenjualanHarianHistory = ({ date, group, balance, count }) => {
	return(
		<Row className="ml-2 mr-2 pb-2 mt-3" style={{borderBottom:'1px solid var(--abu-abu)'}}>
			<Col xs="7">
				<strong>{date}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em', color:'grey'}}>{group}</p>
			</Col>
			<Col xs="5">
				<strong>Rp. {balance}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em', color:'grey'}}>{count} pcs</p>
			</Col>
		</Row>
	)
}