import {Fragment} from 'react';
import {Container, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import './Dashboard.css';
import PeriodeForm from '../../../component/PeriodeForm/PeriodeForm';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const Dashboard = () => {
	ChangeTitle({title:'Dashboard'})
	return(
		<Fragment>
			<main style={{fontFamily:"Roboto", background:'var(--abu-abu)'}}>
				<section>
					{/* === Section 1 - Show Data === */}
					<Container style={{background:'white', borderRadius:'0 0 1em 1em', paddingTop:'.25em'}}>
						<PeriodeForm />
						<h4 style={{fontSize:'1.4em', margin:'.25em 0 0'}}>Rp 600.921.801</h4>
						<Row className="pb-2">
							<Col xs="6">
								<p style={{fontWeight:500}}>Total Omzet</p>
								<RestockBtn restockable={true} />
							</Col>
							<Col xs="6" className="row">
								<Col xs="6" className="p-0">
									<div className="stock-box">
										<h1>30</h1>
										<p>Stok Dijual</p>
									</div>
								</Col>
								<Col xs="6" className="p-0">
									<div className="stock-box">
										<h1>30</h1>
										<p>Total Stok</p>
									</div>
								</Col>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<Container>
						{/* === Section 2 - Menu List === */}
						<Row className="sec">
							<Col className="menu-item p-0" xs="3">
								<Link to="/penjualan">
									<div className="menu"></div>
									<p>Penjualan</p>
								</Link>
							</Col>
							<Col className="menu-item p-0" xs="3">
								<Link to="/stok/permintaan">
									<div className="menu"></div>
									<p>Minta Stok</p>
								</Link>
							</Col>
							<Col className="menu-item p-0" xs="3">
								<Link to="/stok/penerimaan">
									<div className="menu"></div>
									<p>Terima Stok</p>
								</Link>
							</Col>
							<Col className="menu-item p-0" xs="3">
								<Link to="/stok/retur">
									<div className="menu"></div>
									<p>Retur/buang Stok</p>
								</Link>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<Container>
						{/* === Section 3 - Menu List === */}
						<Row className="sec">
							<Col className="menu-item p-0" xs="3">
								<Link to="/setor-wilayah">
									<div className="menu"></div>
									<p>Setor Wilayah</p>
								</Link>
							</Col>
							<Col className="menu-item p-0" xs="3">
								<Link to="/data-customer">
									<div className="menu"></div>
									<p>Data Customer</p>
								</Link>
							</Col>
							<Col className="menu-item p-0" xs="3">
								<Link to="/laporan-stok">
									<div className="menu"></div>
									<p>Laporan Stok</p>
								</Link>
							</Col>
							<Col className="menu-item p-0" xs="3">
								<Link to="/laporan-penjualan">
									<div className="menu"></div>
									<p>Laporan Penjualan</p>
								</Link>
							</Col>
							<Col className="menu-item p-0" xs="3">
								<Link to="/laporan-keuangan">
									<div className="menu"></div>
									<p>Laporan Keuangan</p>
								</Link>
							</Col>
						</Row>
					</Container>
					<div className="space-h-3"></div>
				</section>
			</main>
			<div className='space'></div>
		</Fragment>
	)
}

export default Dashboard;

const RestockBtn = ({ restockable }) => {
	if(restockable){
		return (
			<div>
				<button className="btn btn-success sec-button w-100">TAMBAH STOK</button>
			</div>
		)
	}else{
		return (
			<div>
				<button className="btn btn-danger sec-button w-100">BELUM BOLEH RESTOK</button>
				<p className="text-danger" style={{fontSize:"12px"}}>Ingin RESTOK ? yuk tingkatkan penjualan minimal <strong>10 cup</strong> lagi</p>
			</div>
		)
	}
}