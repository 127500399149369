import { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link} from 'react-router-dom';
import { Filter as FilterSvg } from '../../../component/ImageSvg/ImageSvg';
import { StokReturHistory } from '../../../component/History/History';
import FilterReturBuang from './FilterReturBuang';
import BuangStok from './BuangStok';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const StokReturBuang = () => {
	let [ state, setState ] = useState({
		historys: [
			{id: 0, text:["No. 12345","stok retail 1/2 paket","kode produksi: 7/5/2021"]},
			{id: 1, text:["No. 12346","stok retail 1/2 paket","kode produksi: 8/5/2021"]},
			{id: 2, text:["No. 12347","stok retail 1/2 paket","kode produksi: 9/5/2021"]},
			{id: 3, text:["No. 12348","stok retail 1/2 paket","kode produksi: 11/5/2021"]},
		],
		section2style:{},
		isActiveFilter: false,
		isActiveHandleBuang: false,
	})

	let filterHandle = data => {
		setState({
			...state,
			isActiveFilter: !state.isActiveFilter
		})
	}
	let handleBuangStok = data => {
		setState({
			...state,
			isActiveHandleBuang: !state.isActiveHandleBuang
		})
	}

	ChangeTitle({title:'Paket Stok | Retur'})
	useEffect(()=>{
		let space = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top+'px'
		setState({
			...state,
			section2style:{background:'#fff',padding:'15px 0',borderRadius:'25px 25px 0 0', height:space}
		})
		//eslint-disable-next-line
	}, [])
	return(
		<Fragment>
			{state.isActiveFilter?<FilterReturBuang close={data=>filterHandle(data)} />:null}
			{state.isActiveHandleBuang?<BuangStok close={data=>handleBuangStok(data)} />:null}
			<section style={{background:'#fff',padding:'15px 0',borderRadius:'0 0 25px 25px'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Paket Stok</h1>
				<hr style={{margin:'0 auto',width:'86%'}} />
				<Container className="text-center mt-2">
					<Row className="ml-2 mr-2 stok-nav">
						<Col xs="4 p-0">
							<Link to="/stok/permintaan">Permintaan</Link>
							<hr/>
						</Col>
						<Col xs="4 p-0">
							<Link to="/stok/penerimaan">Penerimaan</Link>
							<hr/>
						</Col>
						<Col xs="4 p-0">
							<Link to="/stok/retur">Retur.Buang</Link>
							<hr className="active"/>
						</Col>
					</Row>
				</Container>
			</section>

			<button onClick={handleBuangStok} className="btn btn-danger btn-block" style={{width:'80%', padding: '10px', margin:'1em auto'}}>BUANG STOK</button>

			<section style={state.section2style}>
				<Container>
					<div className="text-right" style={{margin:'1em 1em 0 0'}}>
						<FilterSvg fill="black" filterClick={filterHandle} /> 
						<hr className='my-2 w-100'/>
					</div>
					{state.historys.map(history=>{
						return <StokReturHistory key={history.id} text={history.text} />
					})}
				</Container>
			</section>

			<div className="space"></div>
		</Fragment>
	)
}

export default StokReturBuang;