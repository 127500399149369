import { Fragment, useContext } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import { Bars, Home, Profile } from '../../component/ImageSvg/ImageSvg';
import { Context } from '../../App';
import './template.css';

const Header = ({ burgerClick }) => {
  let headerSpace = {height:'85px'}
  return (
    <Fragment>
      <header>
        <div className="header">
          <img src="/img/logo-dawetinyong.png" width='30' alt="logo-dawetinyong" />
          <Bars fill="#333" width='20' onClick={burgerClick}/>
        </div>
        <p>Allahumma shalli 'ala Muhammad wa'ala ali Muhammad</p>
      </header>
      <div style={headerSpace}></div>
    </Fragment>
  )
}

const Footer = () => {
  let { state } = useContext(Context)
  return (
    <nav style={{zIndex:'100', boxShadow:'0 0px 10px 0.5px #535353'}} id='navbawah'>
      <div className="menus-wrapper">
        <NavLink to="/" activeClassName="active_" exact>
          <div className="menu-wrapper">
            <Home width="25"/>
            <p>Home</p>
          </div>
        </NavLink>
        {state.userGroup===''?null:
        <NavLink to="/profile" activeClassName="active_">
          <div className="menu-wrapper">
            <Profile width="23"/>
            <p>Profile</p>
          </div>
        </NavLink>}
      </div>
    </nav>
  )
}

const Sidebar = ({ isActive, close }) => {
  let { state, dispatch } = useContext(Context)
  let logout = () => {
    dispatch({type:'LOGOUT'})
    close()
  }
  return(
    <Fragment> 
    <div className={ isActive? "fullscreen-blur opened" : "fullscreen-blur" }  onClick={ close }>
      </div>
    <div className={ isActive? "sidebar opened" : "sidebar" }>
      <img src="/img/logo-dawetinyong.png" alt="logo-dawetinyong.png" style={{maxWidth:'300px'}}/>
          <hr/>
        <Nav vertical>
            <NavItem>
              <Link style={{textDecoration:'none',margin:'0 1em'}} to="/" onClick={ close } >Beranda</Link>
              <hr/>
            </NavItem>
          {state.userGroup===''?null:
            <NavItem>
              <Link style={{textDecoration:'none',margin:'0 1em'}} to="/profile" onClick={ close } >Profil</Link>
              <hr/>
            </NavItem>
          } 
          {state.userGroup==='pusat'?
            <div>
            <NavItem>
              <Link style={{textDecoration:'none',margin:'0 1em'}} to="/setting-harga-jual" onClick={ close } >Setting Harga Jual</Link>
              <hr/>
            </NavItem>
            <NavItem>
              <Link style={{textDecoration:'none',margin:'0 1em'}} to="/setting-paket-retail" onClick={ close } >Setting Paket Retail</Link>
              <hr/>
            </NavItem>
            <NavItem>
              <Link style={{textDecoration:'none',margin:'0 1em'}} to="/setting-paket-acara" onClick={ close } >Setting Paket Acara</Link>
              <hr/>
            </NavItem>
            </div>
          :null} 
          {state.userGroup===''?
            <NavItem>
              <Link style={{textDecoration:'none',margin:'0 1em'}} to="/splash-screen" onClick={ close }>LOGIN</Link>
              <hr/>
            </NavItem>
          :
            <NavItem>
              <Link style={{textDecoration:'none',margin:'0 1em'}} to="/splash-screen" onClick={ logout }>LOGOUT</Link>
              <hr/>
            </NavItem>
          }
          </Nav>
          <div className="copyright">
            <p>digipreneur@2021</p>
          </div>
      </div>
      </Fragment>
  )
}

export default Sidebar;

export { Header, Footer, Sidebar };
