import { Link } from 'react-router-dom';
import { Fragment, useState, useEffect } from 'react';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

let articleList = [
	{link:'/informasi/article1', title:'Ini Judul Artikel Ke-1', date:'20 April 2021'},
	{link:'/informasi/article2', title:'Ini Judul Artikel Ke-2', date:'22 April 2021'},
	{link:'/informasi/article3', title:'Ini Judul Artikel Ke-3', date:'24 April 2021'},
	{link:'/informasi/article4', title:'Ini Judul Artikel Ke-4', date:'26 April 2021'},
]

const Informasi = () => {
	ChangeTitle({title:'Informasi Daweinyong'})
	let [ fillStyle, setFillStyle ] = useState({})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setFillStyle({background:'#fff', padding:'.8em 0', borderRadius:'.8em .8em 0 0', height:'100%',minHeight:String(section2height)+'px'})
	}, [])
	return (
		<Fragment>
			<section style={{background:'#fff', padding:'15px 0 1px', borderRadius:'0 0 25px 25px', marginBottom:'10px'}}>
				<h4 className="text-center m-0">Informasi</h4>
				<hr style={{margin:'1em 1.5em'}}/>
			</section>

			<section style={fillStyle}>
				{articleList.map(article=>{
					return <ArticleList key={article.link} date={article.date} title={article.title} link={article.link} />
				})}
				<div className="space"></div>
			</section>
		</Fragment>
	)
}
export default Informasi;

export const ArticleList = ({ date, title, link }) => {
	return (
		<div>
			<Link to={link} className="clear-link d-block" style={{margin:'1em 1.5em'}}>
				<div style={{background:'var(--abu-abu2)', borderRadius:'.8em', width:'100%', height:'10em'}}></div>
				<div style={{margin:'.3em 1em'}}>
					<p className="text-muted" style={{fontSize:'.7em'}}>{date}</p>
					<p style={{fontWeight:'500'}}>{title}</p>
				</div>
			</Link>
			<hr className="my-1"/>
		</div>
	)
}