import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import MenuList from '../../../component/MenuList/MenuList';
import PeriodeForm from '../../../component/PeriodeForm/PeriodeForm';
import DashboardPusatPagination from '../../../component/Pagination/DashboardPusatPagination';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

let menuList = [
	{link: "/data-hpp", text: "Data HPP"},
	{link: "/terima-uang-penjualan/diterima", text: "Terima Uang Penjualan"},
	{link: "/keuangan-pusat/uang-keluar", text: "Pengeluaran Pusat"},
	{link: "/keuangan-pusat/uang-masuk", text: "Keuangan Pusat"},
	{link: "/laporan-wilayah", text: "Laporan Wilayah"},
	{link: "/laporan-mitra-penjualan", text: "Laporan Mitra Penjualan"},
]

const Dashboard = () => {
	let [ paginationtText, ] = useState([
			['Wilayah A', 15000000000, 2000, 123],
			['Wilayah B', 30000000000, 6000, 144],
			['Wilayah C', 45000000000, 8030, 164],
			['Wilayah D', 60000000000, 10000, 185],
			['Wilayah E', 75000000000, 12000, 206],
			['Wilayah F', 90000000000, 14000, 227]
	])

	ChangeTitle({title:'Dashboard'})

	return(
		<main>
			<section style={{ background:'#fff', borderRadius:'0 0 25px 25px', marginBottom:'10px'}}>
				<Container>
					<PeriodeForm />
					<hr/>
					<Row>
						<Col xs="12">
							<h3 className="mb-0 text-blue">Rp 165.000.000.000</h3>
							<p>omzet penjualan</p>
						</Col>
						<Col xs={{size:8, offset:4}} className='row text-center'>
							<Col>
								<h3 className="text-blue mb-0">2000</h3>
								<p>Stok sisa</p>
							</Col>
							<Col>
								<h3 className="text-blue mb-0">3000</h3>
								<p>Stok terjual</p>
							</Col>
						</Col>
					</Row>
				</Container>
			</section>

			<section>
				<DashboardPusatPagination texts={paginationtText} />
			</section>

			<section  className='sec'>
				<Container>
					<Row>
						{menuList.map(menu=>{
							return <MenuList link={menu.link} text={menu.text} key={menu.link} />
						})}
					</Row>
				</Container>
			</section>

			<div className='space'></div>
		</main>
	)
}
export default Dashboard;