import {Fragment, useState, useEffect, useContext} from 'react';
import {DataCustomerhistory} from '../../../component/History/History';
import {Container} from 'reactstrap';
import Search from '../../../component/Search/Search';
import {Context} from '../../../App';
import TambahCustomer from './TambahCustomer';
import { ChangeTitleEslint } from '../../../component/ChangeTitle/ChangeTitle';

const fakeDb = [
		{id:0, text:['Agus','089134334223']},
		{id:1, text:['Ade','089145634223']}, 
		{id:2, text:['Hera','089345334223']}, 
		{id:3, text:['Indu','089165334223']}
	]

const DataCustomer = () => {
	let { dispatch } = useContext(Context)
	let [ section2style, setSection2Style ] = useState({});
	let [ isActiveTambahCustomer, SetIsActiveTambahCustomer ] = useState(false)

	let handleKontakHubungi = (contact) => {
		alert(`menghubungi kontak ${contact[1]}`);
	}
	let addCustomer = e => {
		console.log(e)
		SetIsActiveTambahCustomer(!isActiveTambahCustomer)
	}
	ChangeTitleEslint({title:'Data Customer'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2Style({padding:'15px 0', margin:'10px 10px', minHeight:String(section2height)+'px'})
	}, [])
	useEffect(()=>{
		dispatch({type:"TOGGLE_BOTTOM_NAV"})
		return () => {
			dispatch({type:"TOGGLE_BOTTOM_NAV"})
		}
		// eslint-disable-next-line 
	}, [])
	return(
		<Fragment>
			{isActiveTambahCustomer?<TambahCustomer close={data=>addCustomer(data)}/>:null}
			<div style={{background:'white'}}>
				<section style={{padding:'15px 0', marginBottom:'10px'}}>
					<h4 className="text-center">Data Customer</h4>
					<hr/>
				</section>

				<section style={section2style}>
					<Container>
						<Search filterClick={()=>alert('click')}/>
						{fakeDb.map(contact=>{
							return <DataCustomerhistory key={contact.id} text={contact.text} hubungi={()=>handleKontakHubungi(contact.text)}/>
						})}
					</Container>
				</section>

				<button className="btn btn-success w-100 btn-block be-footer" onClick={addCustomer}>TAMBAH CUSTOMER</button>
			</div>
		</Fragment>
	)
}

export default DataCustomer;