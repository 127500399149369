import {Fragment, useState, useEffect} from 'react';
import {Container, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import {StokPenerimaanHistory} from '../../../component/History/History';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const StokPenerimaan = (props) => {
	let [ state, setState ] = useState({
			historys: [
				{ no:12345, kategori:"stok retail 1/2 paket", kodeProduksi:"kode produksi: 10/5/2021" },
				{ no:12346, kategori:"stok retail 1/4 paket", kodeProduksi:"kode produksi: 12/5/2021" },
				{ no:12347, kategori:"stok retail 1/8 paket", kodeProduksi:"kode produksi: 13/5/2021" },
				{ no:12348, kategori:"stok retail 1 paket", kodeProduksi:"kode produksi: 14/5/2021" },
				{ no:12349, kategori:"stok retail 1/4 paket", kodeProduksi:"kode produksi: 20/5/2021" },
				{ no:12350, kategori:"stok retail 1/2 paket", kodeProduksi:"kode produksi: 30/5/2021" },
			],
			section2style: {background:'#fff',padding:'15px 0',borderRadius:'25px 25px 0 0', minHeight:'px'}
		})

	ChangeTitle({title:'Paket Stok | Penerimaan'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setState({
			...state,
			section2style:{background:'#fff',padding:'15px 0',borderRadius:'25px 25px 0 0', minHeight:String(section2height)+'px'}
		})
		//eslint-disable-next-line
	}, [])
	return(
		<Fragment>
			<section style={{background:'#fff',padding:'15px 0',borderRadius:'0 0 25px 25px',marginBottom:'10px'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Paket Stok</h1>
				<hr style={{margin:'0 auto',width:'86%'}} />
				<Container className="text-center mt-2">
					<Row className="ml-2 mr-2 stok-nav">
						<Col xs="4 p-0">
							<Link to="/stok/permintaan">Permintaan</Link>
							<hr/>
						</Col>
						<Col xs="4 p-0">
							<Link to="/stok/penerimaan">Penerimaan</Link>
							<hr className="active"/>
						</Col>
						<Col xs="4 p-0">
							<Link to="/stok/retur">Retur.Buang</Link>
							<hr/>
						</Col>
					</Row>
				</Container>
			</section>

			<section style={state.section2style}>
				<Container>
					{state.historys.map(history=>{
						return <StokPenerimaanHistory key={history.no} no={history.no} kategori={history.kategori} kodeProduksi={history.kodeProduksi} link={'/stok/penerimaan/terima'} />
					})}
				</Container>
			</section>
			<div className="space"></div>
		</Fragment>
	)
}

export default StokPenerimaan;