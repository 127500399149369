import { Row, Col } from 'reactstrap';

const History = ({text, removeable}) => {
	return(
		<Row className="ml-2 mr-2 pb-3 mt-3" style={{borderBottom:'1px solid grey'}}>
			<Col xs="4">
				<strong>{text[0]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}}>{text[1]}</p>
			</Col>
			<Col xs={{size:4, offset:4}} className="text-right">
				{removeable?<div>
					<p><strong>{text[2]}</strong></p>
					<img src="/img/wilayah/recyclebin.svg" alt="recyclebin"/>
				</div>:
					<p className="mt-2 text-center"><strong>{text[2]}</strong></p>}
			</Col>
		</Row>
	)
}

export default History;