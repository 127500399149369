import {Fragment, useState, useEffect, useContext} from 'react';
import History from './History';
import {Container} from 'reactstrap';
import Search from '../../../component/Search/Search';
import {Context} from '../../../App';
import TambahMitra from './TambahMitra';
import EditMitra from './EditMitra';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const fakeDb = [
		{id:0, text:['Agus','089134334223']},
		{id:1, text:['Ade','089145634223']}, 
		{id:2, text:['Hera','089345334223']}, 
		{id:3, text:['Indu','089165334223']}
	]

const DataMitra = () => {
	let { dispatch } = useContext(Context)
	let [ section2style, setSection2Style ] = useState({});
	let [ isActiveTambahMitra, SetIsActiveTambahMitra ] = useState(false)
	let [ isActiveEditMitra, SetIsActiveEditMitra ] = useState(false)

	let handleKontakHubungi = (contact) => {
		alert(`menghubungi kontak ${contact[1]}`);
	}
	let addMitraHandle = e => {
		SetIsActiveTambahMitra(!isActiveTambahMitra)
	}
	let editMitraHandle = e => {
		SetIsActiveEditMitra(!isActiveEditMitra)
	}
	ChangeTitle({title:'Data Mitra'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2Style({padding:'15px 0', margin:'10px 10px', minHeight:String(section2height)+'px'})
	}, [])
	useEffect(()=>{
		dispatch({type:"TOGGLE_BOTTOM_NAV"})
		return () => {
			dispatch({type:"TOGGLE_BOTTOM_NAV"})
		}
		// eslint-disable-next-line 
	}, [])
	return(
		<Fragment>
			{isActiveTambahMitra?<TambahMitra close={data=>addMitraHandle(data)}/>:null}
			{isActiveEditMitra?<EditMitra close={data=>editMitraHandle(data)}/>:null}
			<div style={{background:'white'}}>
				<section style={{padding:'15px 0', marginBottom:'10px'}}>
					<h4 className="text-center">Data Mitra</h4>
					<hr className='w-100'/>
				</section>

				<section style={section2style}>
					<Container>
						<Search filterClick={()=>alert('click')}/>
						{fakeDb.map(contact=>{
							return <History key={contact.id} text={contact.text} edit={editMitraHandle} hubungi={()=>handleKontakHubungi(contact.text)}/>
						})}
					</Container>
				</section>
				<div className='space'></div>
				<button className="btn btn-success w-100 btn-block be-footer" onClick={addMitraHandle}>TAMBAH MITRA</button>
			</div>
		</Fragment>
	)
}

export default DataMitra;