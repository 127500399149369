import { useState } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import Popup from '../../../component/Popup/Popup';

const TambahMitra = ({close}) => {
	let [ formData, setFormData ] = useState({nama:'', noWa:'', kurir:'', password:''})
	let handleForm = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}
	let submit = e => {
		e.preventDefault()
		close(formData)
	}
	return (
		<Popup close={close}>
			<div style={{padding:'1em'}}>
				<h5 className="text-center">TAMBAH MITRA</h5>
				<FormGroup>
					<Label to="nama">Nama</Label>
					<Input id="nama" name="nama" type="text" onChange={handleForm}/>
				</FormGroup>
				<FormGroup>
					<Label to="noWa">No. Wa</Label>
					<Input id="noWa" name="noWa" type="number" onChange={handleForm}/>
				</FormGroup>
				<FormGroup>
					<Label to="kurir">Kurir</Label>
					<Input id="kurir" name="kurir" type="select" onChange={handleForm}>
						<option value=""></option>
						<option value="kurir0">kurir0</option>
						<option value="kurir1">kurir1</option>
						<option value="kurir2">kurir2</option>
						<option value="kurir3">kurir3</option>
						<option value="kurir4">kurir4</option>
					</Input>
				</FormGroup>
				<FormGroup>
					<Label to="password">Password Akun</Label>
					<Input id="password" name="password" type="password" onChange={handleForm}/>
				</FormGroup>
				<Button block color="success" onClick={submit}>TAMBAH</Button>
			</div>
		</Popup>
	)
}

export default TambahMitra;