import { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom';

import Dashboard from './Dashboard/Dashboard';
import LaporanProduksi from './LaporanProduksi/LaporanProduksi';
import DataSupplier from './DataSupplier/DataSupplier';
import Profile from './Profile/Profile';

const AppRumahProduksi = () => {
	return (
		<Fragment>
			<Switch>
				<Route path="/profile" exact component={ Profile } />
				<Route path="/data-supplier" exact component={ DataSupplier } />
				<Route path="/laporan-mitra" exact component={ LaporanProduksi } />
				<Route path="/" exact component={ Dashboard } />
			</Switch>
		</Fragment>
	)
}
export default AppRumahProduksi;