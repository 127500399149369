const History = ({text, hubungi, edit}) => {
	return(
		<div className="ml-2 mr-2 pb-2 mt-3 clearfix" style={{borderBottom:'1px solid grey'}}>
			<div className="float-left" style={{width:'fit-content'}}>
				<strong>{text[0]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}} className="text-muted">{text[1]}</p>
			</div>
			<div className="mt-1 float-right" style={{}}>
				<button onClick={edit} className="btn btn-secondary btn-sm" style={{width:'fit-content',borderRadius:'1em', marginRight:'.2em'}}>edit data</button>
				<button onClick={hubungi} className="btn btn-success btn-sm" style={{width:'fit-content',borderRadius:'1em'}}>hubungi</button>
			</div>
		</div>
	)
}

export default History;