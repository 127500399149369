import { Fragment, useEffect, useState } from 'react';
import { ArticleList } from '../Informasi/Informasi';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const TheArticle = () => {
	let [ fillStyle, setFillStyle ] = useState({})
	ChangeTitle({title:'Judul Artikel'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setFillStyle({background:'#fff', padding:'.8em 0', borderRadius:'.8em .8em 0 0', height:'100%',minHeight:String(section2height)+'px'})
	}, [])
	return (
		<Fragment>
			<section style={{background:'#fff', padding:'15px 0 1px', borderRadius:'0 0 25px 25px', marginBottom:'10px'}}>
				<h4 className="text-center m-0">Informasi</h4>
				<hr style={{margin:'1em 1.5em'}}/>
			</section>

			<section style={fillStyle}>
				<ArticleList date={'Sabtu, 21 April 2021'} title={"ini Judul Artikel"} link={'#'} />
				<p style={{margin:'1em 1.2em', textAlign:'justify'}}>Lorem ipsum, dolor sit, amet consectetur adipisicing elit. Unde inventore, nulla consequatur. Culpa ipsum quidem debitis pariatur. Enim beatae eaque itaque dolore id, deserunt molestias sapiente commodi perspiciatis nulla officia.
				Rerum nisi at, laudantium facilis incidunt recusandae praesentium consequatur nam, nostrum corporis dicta perferendis reiciendis voluptatum porro illo error odit earum animi iste facere! Vero praesentium esse atque velit ad.</p>
				<div className="space"></div>
			</section>

			<div className='space'></div>
		</Fragment>
	)
}

export default TheArticle;