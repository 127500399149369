import { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import History  from './History';
import InputPengeluaran from './InputPengeluaran';
import { Context } from '../../../App';
import Pagination from '../../../component/Pagination/Pagination';
import PeriodeForm from '../../../component/PeriodeForm/PeriodeForm';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const historys = [
			{id:0, text:['14 April 2021', 'no. Setor 2313', 'Rp, 250.000']},
			{id:1, text:['30 Maret 2021', 'no. Setor 2312', 'Rp, 3.250.000']},
			{id:2, text:['15 Maret 2021', 'no. Setor 2311', 'Rp, 25.642.000']},
			{id:3, text:['10 Maret 2021', 'no. Setor 2309', 'Rp, 25.452.100']},
		]

const UangMasuk = () => {
	let { dispatch } = useContext(Context)
	let [ section2style, setSection2style ] = useState({})
	let [ isActiveInputPengeluaran, setIsActiveInputPengeluaran ] = useState(false)

	let inputUangKeluar = () => {
		setIsActiveInputPengeluaran(!isActiveInputPengeluaran)
	}
	ChangeTitle({title:'Keuangan Wilayah | Uang Keluar'})
	useEffect(()=>{
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2style({paddingTop:'.2em', marginTop:'.3em', background:'white', borderRadius:'1.3em 1.3em 0 0', minHeight:String(section2height)+'px'})
	}, [])
	useEffect(()=>{
		dispatch({type:"TOGGLE_BOTTOM_NAV"})
		return () => {
			dispatch({type:"TOGGLE_BOTTOM_NAV"})
		}
		// eslint-disable-next-line 
	}, [])
	return (
		<div>
			{isActiveInputPengeluaran?<InputPengeluaran close={inputUangKeluar} />:null}
			<section style={{borderRadius:'0 0 1.3em 1.3em', background:'white', paddingBottom:'1.3em'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Penjualan</h1>
				<hr style={{margin:'0 auto',width:'86%'}} />
				<Container className="text-center mt-2">
					<Row className="ml-2 mr-2 stok-nav">
						<Col xs="6 p-0">
							<Link to="/keuangan-wilayah/uang-masuk">Uang Masuk</Link>
							<hr/>
						</Col>
						<Col xs="6 p-0">
							<Link to="/keuangan-wilayah/uang-keluar">Uang Keluar</Link>
							<hr className="active"/>
						</Col>
					</Row>
					<PeriodeForm className='clearfix my-2 mx-auto' />
				</Container>
			</section>

			<section style={section2style}>
				<Container>
					<p className="ml-3 mt-4 text-center"><b>Total Uang Masuk : Rp 2,980,098,098,876</b></p>
					<hr style={{width:'96%', borderTop:'.25px solid grey', margin:'10px'}}/>
					{historys.map(history=>{
						return <History key={history.id} text={history.text} removeable={true} />
					})}
				</Container>

				<Pagination count={5} go={num=>alert(num)} active={1} />
				<div className='space'></div>
			</section>
			<button className="btn btn-success w-100 btn-block be-footer" onClick={inputUangKeluar}>INPUT UANG KELUAR</button>	
		</div>
	)
}
export default UangMasuk;