import { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const Profile = () => {
	let [ formData, setFormData ] = useState({ nama:'', nomor:'', hp:'', alamat:'', password:'' });
	let handleData = (e) => {
		setFormData({
			[e.target.name]:e.target.value
		})
	}
	let handleSubmit = e => {
		console.log(formData)
	}
	ChangeTitle({title:'Profile'})
	return (
		<div style={{background:'var(--abu-abu0)'}}>
			<section style={{padding:'15px 0'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>PROFILE MITRA</h1>
				<hr style={{width:'90%', margin:'0 auto'}} />
			</section>

			<section className="mx-4">
				<FormGroup>
					<Label for="nama">Nama Mitra</Label>
					<Input type="text" name="nama" id="nama" onChange={e=>handleData(e)} placeholder="nama lengkap" />
				</FormGroup>
				<FormGroup>
					<Label for="nomor">Nomor Mitra</Label>
					<Input type="number" name="nomor" id="nomor" onChange={e=>handleData(e)} placeholder="nomor mitra" />
				</FormGroup>
				<FormGroup>
					<Label for="hp">Nomor WA/Handphone</Label>
					<Input type="number" name="hp" id="hp" onChange={e=>handleData(e)} placeholder="nomor kontak" />
				</FormGroup>
				<FormGroup>
					<Label for="alamat">Alamat</Label>
					<Input type="date" name="alamat" id="alamat" onChange={e=>handleData(e)} placeholder="alamat" />
				</FormGroup>
				<FormGroup>
					<Label for="password">Password</Label>
					<Input type="password" name="password" id="password" onChange={e=>handleData(e)} placeholder="password" />
				</FormGroup>
				<AmbilLokasi koordinat=""/>
				<button className="btn btn-success w-100" onClick={e=>handleSubmit(e)}>SIMPAN PROFILE</button>
			</section>

			<div className='space'></div>
		</div>
	)
}
export default Profile;

const AmbilLokasi = ({ onClick, koordinat }) => {
	if(koordinat){
		return (
			<div className="text-right mb-5">
				<button className="btn btn-success text-white" onClick={onClick}>AMBIL LOKASI</button>
				<p className="text-muted"><small>{koordinat}</small></p>
			</div>
		)
	}else{
		return (
			<div className="text-right mb-5">
				<button className="btn text-white" style={{background:'var(--biruungu)'}} onClick={onClick}>AMBIL LOKASI</button>
				<p className="text-danger"><small>lokasi belum tersimpan</small></p>
			</div>
		)
	}
}