import { useState } from 'react';
import { FormGroup, Label, Input, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const SettingHargaJual = () => {
	let [ formData, setFormData ] = useState({hakMitra:30});
	let handleData = (e) => {
		setFormData({
			[e.target.name]:e.target.value
		})
	}
	let handleSubmit = e => {
		console.log(formData)
	}
	ChangeTitle({title:'Setting Harga Jual'})
	return (
		<div style={{background:'var(--abu-abu0)'}}>
			<section style={{padding:'15px 0'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>PROFILE PUSAT</h1>
				<hr style={{width:'90%', margin:'0 auto'}} />
			</section>

			<section className="mx-4" id="fillProfileKurir">
				<FormGroup>
					<Label for="hakMitra">Hak Mitra</Label>
					<InputGroup>
						<Input id='hakMitra' name='hakMitra' type="number" onChange={handleData} value={formData.hakMitra}/>
						<InputGroupAddon addonType="append">
				        	<InputGroupText>%</InputGroupText>
				        </InputGroupAddon>
				    </InputGroup>
				</FormGroup>
				<FormGroup>
					<Label for="hakWilayah">Hak Wilayah</Label>
					<InputGroup>
						<Input id='hakWilayah' name='hakWilayah' type="number" onChange={handleData}/>
						<InputGroupAddon addonType="append">
				        	<InputGroupText>%</InputGroupText>
				        </InputGroupAddon>
				    </InputGroup>
				</FormGroup>
				<FormGroup>
					<Label for="hakWilayah">Hak Pusat</Label>
					<InputGroup>
						<Input id='hakWilayah' name='hakWilayah' type="number" onChange={handleData}/>
						<InputGroupAddon addonType="append">
				        	<InputGroupText>%</InputGroupText>
				        </InputGroupAddon>
				    </InputGroup>
				</FormGroup>
				<FormGroup>
					<Label for="harjaJual">Harga Jual Retail(per cup)</Label>
					<InputGroup>
						<InputGroupAddon addonType="prepend">
				        	<InputGroupText>Rp </InputGroupText>
				        </InputGroupAddon>
						<Input id='harjaJual' name='harjaJual' type="number" onChange={handleData}/>
				    </InputGroup>
				</FormGroup>
				<FormGroup>
					<Label for="harjaJual">Harga Jual Acara/Event(per cup)</Label>
					<InputGroup>
						<InputGroupAddon addonType="prepend">
				        	<InputGroupText>Rp </InputGroupText>
				        </InputGroupAddon>
						<Input id='harjaJual' name='harjaJual' type="number" onChange={handleData}/>
				    </InputGroup>
				</FormGroup>
				<FormGroup row>
					<Col>
						<Label to='isi1PaketRetail'>Isi 1 paket retail(cup)</Label>
						<Input id='isi1PaketRetail' name='isi1PaketRetail' type='number' onChange={handleData}/>
					</Col>
					<Col>
						<Label to='isi1per2PaketRetail'>Isi 1/2 paket retail(cup)</Label>
						<Input id='isi1per2PaketRetail' name='isi1per2PaketRetail' type='number' onChange={handleData}/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Col>
						<Label to='isi1PaketAcara'>Isi 1 paket acara(cup)</Label>
						<Input id='isi1PaketAcara' name='isi1PaketAcara' type='number' onChange={handleData}/>
					</Col>
					<Col>
						<Label to='isi1per2PaketAcara'>Isi 1/2 paket acara(cup)</Label>
						<Input id='isi1per2PaketAcara' name='isi1per2PaketAcara' type='number' onChange={handleData}/>
					</Col>
				</FormGroup>
				<button className="btn btn-success w-100 mt-4" onClick={handleSubmit} name='simpanProfile'>SIMPAN PROFILE</button>
			</section>

			<div className="space"></div>
		</div>
	)
}

export default SettingHargaJual;