import { useState } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import Popup from '../../../component/Popup/Popup';

const EditKurir = ({close}) => {
	let [ formData, setFormData ] = useState({nama:'', noWa:'', password:''})
	let handleForm = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}
	let submit = e => {
		e.preventDefault()
		close(formData)
	}
	return (
		<Popup close={close}>
			<div style={{padding:'1em'}}>
				<h5 className="text-center">EDIT KURIR</h5>
				<FormGroup>
					<Label to="nama">Nama</Label>
					<Input id="nama" name="nama" type="text" onChange={handleForm}/>
				</FormGroup>
				<FormGroup>
					<Label to="noWa">No. Wa</Label>
					<Input id="noWa" name="noWa" type="number" onChange={handleForm}/>
				</FormGroup>
				<FormGroup>
					<Label to="password">Password Akun</Label>
					<Input id="password" name="password" type="password" onChange={handleForm}/>
				</FormGroup>
				<Button block color="secondary" onClick={submit}>EDIT</Button>
			</div>
		</Popup>
	)
}

export default EditKurir;