import { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const Profile = () => {
	let [ formData, setFormData ] = useState({ nama:'', manager:'', hp:'', alamat:'', password:'' });
	let handleData = (e) => {
		setFormData({
			[e.target.name]:e.target.value
		})
	}
	let handleSubmit = e => {
		console.log(formData)
	}
	return (
		<div style={{background:'var(--abu-abu0)'}}>
			<section style={{padding:'15px 0'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>PROFILE WILAYAH</h1>
				<hr style={{width:'90%', margin:'0 auto'}} />
			</section>

			<section className="mx-4">
				<FormGroup>
					<Label for="nama">Nama Wilayah</Label>
					<Input type="text" name="nama" id="nama" onChange={handleData} placeholder="nama lengkap" />
				</FormGroup>
				<FormGroup>
					<Label for="manager">Manager Wilayah</Label>
					<Input type="number" name="manager" id="manager" onChange={handleData} placeholder="manager" />
				</FormGroup>
				<FormGroup>
					<Label for="hp">Nomor WA/Handphone</Label>
					<Input type="number" name="hp" id="hp" onChange={handleData} placeholder="nomor kontak" />
				</FormGroup>
				<FormGroup>
					<Label for="alamat">Alamat</Label>
					<Input type="text" name="alamat" id="alamat" onChange={handleData} placeholder="alamat" />
				</FormGroup>
				<FormGroup>
					<Label for="password">Password</Label>
					<Input type="password" name="password" id="password" onChange={handleData} placeholder="password" />
				</FormGroup>
				
				<button className="btn btn-success w-100" onClick={handleSubmit}>SIMPAN PROFILE</button>
			</section>

			<div className='space'></div>
			<div className='space'></div>
		</div>
	)
}
export default Profile;