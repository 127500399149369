import { useEffect, useState, useContext } from 'react';
import { Container, Table, Button, Input } from 'reactstrap';
import { Context } from '../../../App';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const DataHpp = () => {
	let { dispatch } = useContext(Context)
	let [ fillStyle, setFillStyle ] = useState({})
	let [ formData, setFormData ] = useState({
		0:{name:'Gula', modal:999, jumlah:50, totalBiaya:30000},
		1:{name:'Pandan', modal:999, jumlah:50, totalBiaya:30000},
		2:{name:'Creamer', modal:999, jumlah:50, totalBiaya:30000},
		3:{name:'Tepung Beras Tapioka', modal:999, jumlah:50, totalBiaya:30000},
		4:{name:'Air Galon', modal:999, jumlah:50, totalBiaya:30000},
		5:{name:'Sedotan', modal:999, jumlah:50, totalBiaya:30000},
		6:{name:'Es', modal:999, jumlah:50, totalBiaya:30000},
		7:{name:'Plastik 1', modal:999, jumlah:50, totalBiaya:30000},
		8:{name:'Plastik 2', modal:999, jumlah:50, totalBiaya:30000},
		9:{name:'Plastik 4', modal:999, jumlah:50, totalBiaya:30000},
		10:{name:'Cup', modal:999, jumlah:50, totalBiaya:30000},
		11:{name:'Gas', modal:999, jumlah:50, totalBiaya:30000},
		12:{name:'Garam', modal:999, jumlah:50, totalBiaya:30000},
		13:{name:'Kapur', modal:999, jumlah:50, totalBiaya:30000},
	})

	let formDataArry = Object.keys(formData).map(data=>formData[data])
	let handleData = (e) => {
		e.preventDefault()
		setFormData({
			...formData,
			[e.target.name]:{
				...formData[e.target.name],
				[e.target.dataset.col]:e.target.value.trim()
			}
		})
	}

	ChangeTitle({title:'Data HPP'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setFillStyle({minHeight:String(section2height)+'px'})
	}, [])
	useEffect(() => {
		dispatch({type:'TOGGLE_BOTTOM_NAV'})
		return ()=>{ dispatch({type:'TOGGLE_BOTTOM_NAV'}) }
		// eslint-disable-next-line
	},[])

	let inputStyle = {background:'white',borderRadius:'.5em',padding:'.5em',textAlign:'center'}
	let inputStyleTh = {background:'white',borderRadius:'.5em',padding:'.5em',textAlign:'left'}
	return (
		<div style={{background:'var(--ligth)'}}>
			<section style={{padding:'15px 0', marginBottom:'10px'}}>
				<h4 className="text-center text-blue">Rekaputulasi Modal Per Cup</h4>
				<hr/>
			</section>

			<Container fluid>
				<div style={{boxShadow:'0px 4px 4px 0px #00000040 inset', textAlign:'center', padding:'.5em', borderRadius:'1em'}}>
					<h3>Rp 34.000.000.000</h3>
					<p>Total Modal HPP</p>
				</div>
			</Container>

			<section style={fillStyle}>
				<Container fluid>
					<Table className='mt-4'>
						<thead className='text-center'>
					        <tr>
					          <th>Item Bahan</th>
					          <th>Modal Per Cup(Rp)</th>
					          <th>Jumlah</th>
					          <th>Total Biaya(Rp)</th>
					        </tr>
					      </thead>
					      <tbody>
					      	{formDataArry.map((fda, index)=>{
					      		return <tr key={index}>
						          <th>
						          	<Input type='text' name={index} data-col='name' value={formData[index]['name']} style={inputStyleTh} onChange={handleData}/>
						          </th>
						          <th>
						          	<Input type='number' name={index} data-col='modal' value={formData[index]['modal']} style={inputStyle} onChange={handleData}/>
						          </th>
						          <th>
						          	<Input type='number' name={index} data-col='jumlah' value={formData[index]['jumlah']} style={inputStyle} onChange={handleData}/>
						          </th>
						          <th>
						          	<Input type='number' name={index} data-col='totalBiaya' value={formData[index]['totalBiaya']} style={inputStyle} onChange={handleData}/>
						          </th>
						        </tr>
					      	})}
					      </tbody>
					</Table>
				</Container>
			</section>
			<div className='space'></div>
			<Button color='success' block className='be-footer'>SIMPAN</Button>
		</div>
	)
}
export default DataHpp;