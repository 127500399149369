import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationExport = ({count, go, first, prev, next, last, active}) => {
	let links = []
	for (let i=1;i<=count;i++) {
		links.push(i)
	}
  return (
	<div style={{margin:'1em auto', width:'fit-content'}}>
	    <Pagination>
	    <PaginationItem>
	        <PaginationLink first href="#" onClick={first} />
	      </PaginationItem>
	      <PaginationItem>
	        <PaginationLink previous href="#" onClick={prev} />
	      </PaginationItem>
	      {links.map(link=>{
	      	return <PaginationItem key={link} active={link===active?true:false}>
		        <PaginationLink href="#" onClick={()=>go(link)}>
		          {link}
		        </PaginationLink>
		      </PaginationItem>
	      })}
	      <PaginationItem>
	        <PaginationLink next href="#" onClick={next} />
	      </PaginationItem>
	      <PaginationItem>
	        <PaginationLink last href="#" onClick={last} />
	      </PaginationItem>
	    </Pagination>
	</div>
  );
}

export default PaginationExport;