import { Button, Row, Col } from 'reactstrap';

const History = ({text, diterimaConfirm}) => {
	let status = () => {
		if(text[3]){
			return <Button size="sm" color="secondary" style={{width:'100%', borderRadius:'1em'}}>Diterima</Button>
		}else{
			return <Button size="sm" color="success" style={{width:'100%', borderRadius:'1em'}} onClick={diterimaConfirm}>Terima</Button>
		}
	}
	return(
		<Row className="ml-2 mr-2 pb-2 mt-3" style={{borderBottom:'1px solid grey'}}>
			<Col xs="4">
				<strong>{text[0]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}}>{text[1]}</p>
			</Col>
			<Col xs="4">
				<p className="mt-2 text-center"><strong>{text[2]}</strong></p>
			</Col>
			<Col xs="4" className="font-weight-bold text-center">
				{status()}
			</Col>
		</Row>
	)
}
export default History;