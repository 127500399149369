import { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Label, Input } from 'reactstrap';
import History from './History';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const LaporanStok = () => {
	let [ state, setState ] = useState({
		historys: [
			{id:0, text:['23189', '10/5/2021', 802, 20]},
			{id:1, text:['23188', '23/3/2021', 250, 24]},
			{id:2, text:['23187', '09/2/2021', 991, 4]},
		],
		formData: {},
		section2style: {},
	})
	let handleData = e => {
		setState({
			...state,
			formData:{
				...state.formData,
				[e.target.name]:e.target.value
			}
		})
	}
	ChangeTitle({title:'Laporan Stok'})
	useEffect(()=>{
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setState({
			...state,
			section2style:{padding:'15px 0', margin:'10px 10px', minHeight:String(section2height)+'px'}
		})
		//eslint-disable-next-line
	}, [])
	return(
		<Fragment>
			<div style={{background:'white'}}>
				<section style={{padding:'15px 0 1px', marginBottom:'10px'}}>
					<h4 className="text-center">Laporan Stok</h4>
					<hr className="mx-auto mb-2"/>
					<Container>
						<Row>
							<Col xs='12'>
								<p className='font-weight-bold'>Periode Penerimaan Stok</p>
							</Col>
							<Col xs='12'>
								<Form inline className='clearfix'>
						            <Input className='float-left' style={{width:'40%'}} bsSize='sm' type="date" name="tanggalStart" id="tanggalStart" onChange={handleData}/>
						            <Label className='float-left' style={{width:'20%', marginTop:'.3em', textAlign:'center', margin:'0 !important'}} for="tanggalEnd">s/d</Label>
						            <Input className='float-left' style={{width:'40%'}} bsSize='sm' type="date" name="tanggalEnd" id="tanggalEnd" onChange={handleData}/>
						        </Form>
						        <hr className='w-100 mt-2'/>
							</Col>
						</Row>
					</Container>
				</section>

				<section style={state.section2style}>
					<Container>
						{state.historys.map(history=>{
							return <History key={history.id} text={history.text} />
						})}
					</Container>
				</section>
			</div>
		</Fragment>
	)
}

export default LaporanStok;