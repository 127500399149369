import { useState, useContext, useEffect } from 'react';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Context } from '../../App';
import ChangeTitle from '../../component/ChangeTitle/ChangeTitle';

const SplashScreen = (props) => {
	ChangeTitle({title:'Login Dawetinyong'})
	const [ formData, setFormData ] = useState({nohp:'', password:''})
	const { dispatch } = useContext(Context)

	const handleForm = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
		// console.log(props.history)
	}
	const submitForm = () => {
		if(formData.password){ 
			dispatch({type:"LOGIN", group:formData.password}) 
			props.history.push('/')
		}
	}
	useEffect(()=>{
		dispatch({type:'TOGGLE_BOTTOM_NAV'})
		return ()=>{
			dispatch({type:'TOGGLE_BOTTOM_NAV'})
		}
		//eslint-disable-next-line
	},[])
	return(
		<Container style={{backgroundColor:'#ecf6ee', padding:'0 2em'}} fluid>
			<div className='text-center'>
				<img src="/img/logo-dawetinyong.png" alt="logo" className="mt-2"/>
			</div>
			<h4 className="text-center" style={{font:"30px Aladin"}}>Ada Doa Di Setiap Tetesnya</h4>
			<Form>
				<FormGroup>
		    	    <Label for="noHpInput">no handphone</Label>
		        	<Input type="number" name="nohp" id="noHpInput" onChange={e=>handleForm(e)} />
		      	</FormGroup>
				<FormGroup>
		    	    <Label for="passwordInput">password</Label>
		        	<Input type="text" name="password" id="passwordInput" onChange={e=>handleForm(e)} />
		      	</FormGroup>
		      	<Button className="form-control btn-success mt-2" onClick={submitForm}>Login</Button>
		      	<p className="test-mute text-center mt-4">mitra | wilayah | kurir | rumahproduksi | pusat</p>
		      	<p className="test-mute text-center ">lupa password? <Link to="#">RESET</Link></p>
			</Form>	
			<div className="space"></div>
		</Container>
	)
}

export default SplashScreen;