import React, { useState } from 'react';
import {
  Carousel as Carousel2,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

const items = [
  { id:1, img:'/img/pembeli/carousel/image1.jpg' },
  { id:2, img:'/img/pembeli/carousel/image2.jpg' },
  { id:3, img:'/img/pembeli/carousel/image3.jpg' },
  { id:4, img:'/img/pembeli/carousel/image4.jpg' },
  { id:5, img:'/img/pembeli/carousel/image5.jpg' },
];

const Carousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map(item => {
    return (
      <CarouselItem
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <img src={item.img} alt={item.img} width="100%"/>
      </CarouselItem>
    );
  });

  return (
    <div style={props.style}>
      <style>{`
        .carousel li { background:var(--hijau); border:none; width:.4em; height:.4em; border-radius:.15em; margin:0 .5em; opacity:1;}
        .carousel li.active { background:white; }
      `}</style>
      <Carousel2
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel2>
    </div>
  );
}

export default Carousel;