import { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Carousel from '../../../component/Carousel/Carousel';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const Dashboard = () => {
	ChangeTitle({title:'Dashboard'})
	let navLinkStyle = {background:'white', fontWeight:'500', borderRadius:'1em', padding:'.4em', boxShadow:'0px 4px 4px 0px #00000040'}
	let socLinkStyle = {background:'white', padding:'.7em', textAlign:'center', borderRadius:'1em'}
	return (
		<Fragment>
		  {/* 1 */}
			<div style={{background:'white', borderRadius:'0 0 .7em .7em'}}>
				<Container className="pb-3">
					<p className='pt-2'>Jumat, 30 April 2021</p>
					<hr className="garis-halus mb-2"/>
					<Carousel style={{borderRadius:'.7em', overflow:'hidden'}}/>
				</Container>
			</div>

			{/* 2 */}
			<Container className='mt-2'>
				<div style={{background:'var(--hijau2)', borderRadius:'1em', padding:'1.5em'}}>
					<Link to="/search-mitra" className="clear-link d-block" style={navLinkStyle}>
						<div className="clearfix d-block m-auto w-fit-content">
							<img src="/img/pembeli/image.png" alt="logo-dawetinyong.png" className="float-left" />
							<p className="float-left mt-2 ml-2" >Pesan Dawet Sekarang</p>
						</div>
					</Link>
					<Link to="/informasi" className="clear-link d-block mt-3" style={{background:'white', fontWeight:'500', borderRadius:'1em', padding:'.4em', boxShadow:'0px 4px 4px 0px #00000040'}}>
						<div className="clearfix d-block m-auto w-fit-content">
							<img src="/img/pembeli/info.svg" alt="info-dawetinyong.png" className="float-left py-1" width="30" />
							<p className="float-left mt-2 ml-2" >Informasi Dawet Inyong</p>
						</div>
					</Link>
				</div>
			</Container>

			{/* 3 */}
			<div className='mt-4' style={{padding:'0 2em'}}>
				<p style={{fontWeight:'500', padding:'0 20%', textAlign:'center'}}>Yuk ikuti sosial media dawet inyong</p>
				<Container className='mt-2'><Row>
					<Col xs='4'><Link to="/instagram"><div style={socLinkStyle}>
						<img src="/img/pembeli/Instagram.svg" alt="Instagram.svg" white="100%" />
					</div></Link></Col>
					<Col xs='4'><Link to="/facebook"><div style={socLinkStyle}>
						<img src="/img/pembeli/Facebook.svg" alt="Facebook.svg" white="100%" />
					</div></Link></Col>
					<Col xs='4'><Link to="/youtube"><div style={socLinkStyle}>
						<img src="/img/pembeli/Youtube.svg" alt="Youtube.svg" white="100%" style={{padding:"8px 0"}}/>
					</div></Link></Col>
				</Row></Container>
			</div>

			<div className="space"></div>
		</Fragment>
	)
}

export default Dashboard;