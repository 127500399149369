import {Component} from 'react'
import {Container, Row, Col, FormGroup, Label, Input} from 'reactstrap';

import Popup from '../../../component/Popup/Popup';

export default class FilterReturBuang extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData:{
				tanggalStart: null,
				tanggalFinish: null,
				kategoriPenjual : null
			}
		}
	}
	closePopup = () => {
		this.props.close(null)
	}
	handleData = e => {
		let keys = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[keys]: value
			}
		})
	}
	handleFilter = (e) => {
		e.preventDefault()
		this.props.close(this.state.formData)
	}
	render(){
		return(
			<Popup close={this.closePopup}>
				<h4 className="text-center">Filter</h4>
				<Container>
					<Label for="tanggal">Rentan Waktu</Label>
					<Row>
						<Col xs="5">
							<FormGroup>
								<Input type="date" name="tanggalStart" id="tanggal" onChange={this.handleData} />
							</FormGroup>
						</Col>
						<Col xs="2" className="text-center mt-1">s/d</Col>
						<Col xs="5">
							<FormGroup>
								<Input type="date" name="tanggalFinish" id="tanggal2" onChange={this.handleData} />
							</FormGroup>
						</Col>
					</Row>
					<FormGroup>
						<Label for="status">Status</Label>
				        <Input type="select" name="status" id="status" onChange={this.handleData} >
				          	<option value=''></option>
				          	<option value="Diminta">Diminta</option>
				          	<option value="Ditolak">Ditolak</option>
				          	<option value="Diterima">Diterima</option>
				        </Input>
					</FormGroup>
					<button className="btn btn-block btn-success" onClick={e=>this.handleFilter(e)}>FILTER</button>
				</Container>
			</Popup>
		)
	}
}