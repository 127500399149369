import { useState } from 'react';
import Popup from '../../../component/Popup/Popup';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';

const Filter = ({close}) => {
	let [ formData, setFormData ] = useState({ tanggalStart:'', tanggalFinish:'', status:'' })
	let handleData = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}
	let submit = e => {
		e.preventDefault()
		close(formData)
	}
	return (
		<Popup close={close}>
			<h4 className="text-center">Filter</h4>
			<Container>
				<Label for="tanggal">Rentan Waktu</Label>
				<Row>
					<Col xs="5">
						<FormGroup>
							<Input type="date" name="tanggalStart" id="tanggal" onChange={handleData} />
						</FormGroup>
					</Col>
					<Col xs="2" className="text-center mt-1">s/d</Col>
					<Col xs="5">
						<FormGroup>
							<Input type="date" name="tanggalFinish" id="tanggal2" onChange={handleData} />
						</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<Label for="status">Status</Label>
			        <Input type="select" name="status" id="status" onChange={handleData} >
			          	<option value=''></option>
			          	<option value="request">request</option>
			          	<option value="process">process</option>
			          	<option value="done">done</option>
			        </Input>
				</FormGroup>
				<button className="btn btn-block btn-success" onClick={submit}>FILTER</button>
			</Container>
		</Popup>
	)
}

export default Filter;