import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

const StokPermintaanHistory = ({text,cssClassStatus}) => {
	return(
		<Row>
			<Col xs="4">
				<strong>{text[0]}</strong>
				<p className="text-muted">{text[1]}</p>
			</Col>
			<Col xs="4">
				<p className="text-center mr-1"><b>{text[2]}</b></p>
			</Col>
			<Col xs="4">
			 <strong className={cssClassStatus}>{text[3]}</strong>
			 <p className="text-muted">{text[4]}</p>
			</Col>
			<hr className="mt-0"/>
		</Row>
	)
}

const StokPenerimaanHistory = ({ no, kategori, kodeProduksi, link}) => {
	return(
		<Row>
			<Col xs="9">
				<p className="mb-0"><strong>No. {no}</strong></p>
				<p className="mb-0 text-muted">{kategori}</p>
				<p className="mb-0 text-muted">{kodeProduksi}</p>
			</Col>
			<Col xs="3">
				<Link to={link} className="mt-3 btn-sm btn btn-success">terima</Link>
			</Col>
			<hr className="mt-2" style={{borderWidth:'2px'}}/>
		</Row>
	)
}

const StokReturHistory = ({text}) => {
	return(
		<Row>
			<Col xs="8">
				<p className="mb-0"><strong>{text[0]}</strong></p>
				<p className="mb-0 text-muted">{text[1]}</p>
				<p className="mb-0 text-muted">{text[2]}</p>
			</Col>
			<Col xs="4">
				<button className="mt-3 btn-sm btn btn-danger">minta retur</button>
			</Col>
			<hr className="mt-2" style={{borderWidth:'2px'}}/>
		</Row>
	)
}

const DataCustomerhistory = ({text, hubungi}) => {
	return(
		<Row className="ml-2 mr-2 pb-2 mt-3" style={{borderBottom:'1px solid grey'}}>
			<Col xs="8">
				<strong>{text[0]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}} className="text-muted">{text[1]}</p>
			</Col>
			<Col xs="4" className="mt-1">
				<button onClick={hubungi} className="btn btn-success btn-sm">hubungi</button>
			</Col>
		</Row>
	)
}



const LaporanPenjualanHistory = ({text}) => {
	return(
		<Row className="pl-3">
			<Col xs="5">
				<p className="mb-0"><strong>{text[0]}</strong></p>
				<p className="mb-0 text-muted">{text[1]}</p>
			</Col>
			<Col xs="2">
			</Col>
			<Col xs="5">
				<p className="mb-0"><strong>Rp. {text[2]}</strong></p>
				<p className="mb-0 text-muted">{text[3]} pcs</p>
			</Col>
			<hr className='my-2'/>
		</Row>
	)
}

const LaporanKeuanganHistory = ({text}) => {
	return(
		<Row className="pl-3">
			<Col xs="5">
				<p className="mb-0"><strong>{text[0]}</strong></p>
				<p className="mb-0 text-muted">{text[1]}</p>
			</Col>
			<Col xs="2">
			</Col>
			<Col xs="5">
				<p className="mb-0"><strong>Rp. {text[2]}</strong></p>
			</Col>
			<hr/>
		</Row>
	)
}

const DashboardRumahProduksiHistory = ({text}) => {
	return(
		<Row>
			<Col xs="7">
				<p className="mb-0"><b>{text[0]}</b></p>
				<p className="mb-0 text-muted" style={{fontSize:'.8em'}}>Produksi {text[1]} paket</p>
			</Col>
			<Col xs="5">
				<p className="mb-0" style={{fontSize:'.8em'}}>Kode: {text[2]}</p>
				<p className="mb-0" style={{fontSize:'.8em'}}>{text[3]} pcs</p>
			</Col>
			<hr/>
		</Row>
	)
}

const SetorWilayahHistory = ({text}) => {
	let status = () => {
		if(text[3]==='request'){ return ( <p className="text-danger">request</p> ) }
		if(text[3]==='process'){ return ( <p className="text-primary">process</p> ) }
		if(text[3]==='done'){ return ( <p className="text-success">done</p> ) }
	}
	return(
		<Row className="ml-2 mr-2 pb-2 mt-3" style={{borderBottom:'1px solid grey'}}>
			<Col xs="4">
				<strong>{text[0]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}}>{text[1]}</p>
			</Col>
			<Col xs="4">
				<p className="mt-2 text-center"><strong>{text[2]}</strong></p>
			</Col>
			<Col xs="4" className="font-weight-bold text-center">
				{status()}
			</Col>
		</Row>
	)
}

export {
	StokPermintaanHistory,
	StokPenerimaanHistory,
	StokReturHistory,
	SetorWilayahHistory,
	DataCustomerhistory,
	LaporanPenjualanHistory,
	LaporanKeuanganHistory,
	DashboardRumahProduksiHistory
};