import { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom';

import Dashboard from './Dashboard/Dashboard';
import KirimUangPenjualan from './KirimUangPenjualan/KirimUangPenjualan';
import Proses from './TerimaUangPenjualan/Proses';
import Diterima from './TerimaUangPenjualan/Diterima';
import UangMasuk from './KeuanganWilayah/UangMasuk';
import UangKeluar from './KeuanganWilayah/UangKeluar';
import Permintaan from './PermintaanStok/Permintaan';
import ReturBuang from './PermintaanStok/ReturBuang';
import TanggapiRetur from './PermintaanStok/TanggapiRetur';

import LaporanCup from './LaporanCup/LaporanCup';
import LaporanPenjualan from './LaporanPenjualan/LaporanPenjualan';
import DataKurir from './DataKurir/DataKurir';
import DataMitra from './DataMitra/DataMitra';

import Profile from './Profile/Profile'

const AppWilayah  = () => {
	return (
		<Fragment>
			<Switch>
				<Route path="/profile" exact component={ Profile } />

				<Route path="/data-mitra" exact component={ DataMitra } />
				<Route path="/data-kurir" exact component={ DataKurir } />
				<Route path="/laporan-penjualan" exact component={ LaporanPenjualan } />
				<Route path="/laporan-cup" exact component={ LaporanCup } />

				<Route path="/permintaan-stok/permintaan" exact component={ Permintaan } />
				<Route path="/permintaan-stok/retur" exact component={ ReturBuang } />
				<Route path="/permintaan-stok/retur/:noRetur" exact component={ TanggapiRetur } />
				<Route path="/keuangan-wilayah/uang-masuk" exact component={ UangMasuk } />
				<Route path="/keuangan-wilayah/uang-keluar" exact component={ UangKeluar } />
				<Route path="/kirim-uang-penjualan" exact component={ KirimUangPenjualan } />
				<Route path="/terima-uang-penjualan/proses" exact component={ Proses } />
				<Route path="/terima-uang-penjualan/diterima" exact component={ Diterima } />

				<Route path="/" exact component={ Dashboard } />
			</Switch>
		</Fragment>		
	)
}

export default AppWilayah;