import { Row, Col } from 'reactstrap';
const History = ({text}) => {
	return(
		<Row>
			<Col xs="6">
				<p className="mb-0"><strong>Kode Produksi {text[0]}</strong></p>
				<p className="mb-0 text-muted">terima: {text[1]}</p>
			</Col>
			<Col xs="3">
				<h4 className="mb-1 text-center" style={{color:"#17335B"}}>{text[2]}</h4>
				<p className="text-center mb-1" style={{lineHeight:'1em', fontSize:'1em'}}>Stok Terjual</p>
			</Col>
			<Col xs="3">
				<h4 className="mb-1 text-center" style={{color:"#17335B"}}>{text[3]}</h4>
				<p className="text-center mb-1" style={{lineHeight:'1em', fontSize:'1em'}}>Stok Terjual</p>
			</Col>
			<hr className="my-2" style={{borderWidth:'2px'}}/>
		</Row>
	)
}

export default History;