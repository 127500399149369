import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Search from '../../../component/Search/Search';
import History  from './History';
import TerimaConfirm from './TerimaConfirm';
import PeriodeForm from '../../../component/PeriodeForm/PeriodeForm';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const historys = [
			{id:0, text:['14 April 2021', 'no. Setor 2313', 'Rp, 250.000', 'kode mitra', false]},
			{id:1, text:['30 Maret 2021', 'no. Setor 2312', 'Rp, 3.250.000', 'kode mitra', false]},
			{id:2, text:['15 Maret 2021', 'no. Setor 2311', 'Rp, 25.642.000', 'kode mitra', false]},
			{id:3, text:['10 Maret 2021', 'no. Setor 2309', 'Rp, 25.452.100', 'kode mitra', false]},
		]

const Proses = () => {
	let [ section2style, setSection2style ] = useState({})
	let [ isActiveDiterimaConform, setIsActiveDiterimaConform ] = useState(false)

	let diterimaConfirm = () => {
		setIsActiveDiterimaConform(!isActiveDiterimaConform)
	}
	ChangeTitle({title:'Setoran Penjualan Wilayah | Proses'})
	useEffect(()=>{
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2style({minHeight:String(section2height)+'px'})
	}, [])
	return (
		<div  style={{background:'#fff',padding:'15px 0'}}>
			{isActiveDiterimaConform?<TerimaConfirm close={diterimaConfirm} />:null}
			<section>
					<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Setoran Penjualan Wilayah</h1>
					<hr style={{margin:'0 auto',width:'86%'}} />
					<Container className="text-center mt-2">
						<Row className="ml-2 mr-2 stok-nav">
							<Col xs="6 p-0">
								<Link to="/terima-uang-penjualan/proses">Proses</Link>
								<hr className="active"/>
							</Col>
							<Col xs="6 p-0">
								<Link to="/terima-uang-penjualan/diterima">Diterima</Link>
								<hr/>
							</Col>
						</Row>
						<PeriodeForm className='clearfix mx-auto my-2' />
					</Container>
				</section>

				<section style={section2style}>
					<Container>
						<Search />
						<p className="ml-3 mt-4 text-center"><b>Riwayat Setoran</b></p>
						<hr style={{width:'96%', borderTop:'.25px solid grey', margin:'10px'}}/>
						{historys.map(history=>{
							return <History key={history.id} text={history.text} diterimaConfirm={diterimaConfirm} />
						})}
					</Container>
				</section>
		</div>
	)
}
export default Proses;