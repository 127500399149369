import { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Table } from 'reactstrap';
import Search from '../../../component/Search/Search';
import { Order } from '../../../component/ImageSvg/ImageSvg';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const tableDatas = [
		{mitra:'001', totalPenjualan:'Rp 23.000.000'},
		{mitra:'002', totalPenjualan:'Rp 23.000.000'},
		{mitra:'003', totalPenjualan:'Rp 23.000.000'},
		{mitra:'004', totalPenjualan:'Rp 23.000.000'},
		{mitra:'005', totalPenjualan:'Rp 23.000.000'},
		{mitra:'006', totalPenjualan:'Rp 23.000.000'},
		{mitra:'007', totalPenjualan:'Rp 23.000.000'},
		{mitra:'008', totalPenjualan:'Rp 23.000.000'},
		{mitra:'009', totalPenjualan:'Rp 23.000.000'},
	]

const LaporanMitraPenjualan = () => {
	let [ section2style, setSection2Style ] = useState({});
	
	ChangeTitle({title:'Laporan Mitra Penjualan'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2Style({padding:'15px 0', margin:'10px', minHeight:String(section2height)+'px'})
	}, [])
	let boxStyle = {background:'var(--green)', borderRadius:'1.2em', color:'white', padding:'1em'}
	return(
		<Fragment>
			<div style={{background:'white'}}>
				<section style={{padding:'15px 0', marginBottom:'10px'}}>
					<h4 className="text-center text-blue">Laporan Mitra Penjualan</h4>
					<Container>
						<hr className='my-3 w-100'/>
						<FormGroup row>
							<Label  xs={3} to='kodeProduksi' style={{fontSize:'.9em', padding:'0 0 0 .9em'}}>Pilih Wilayah</Label>
							<Col xs={9}>
								<Input bsSize='sm' id='kodeProduksi' name='kodeProduksi' type="text" />
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label xs={3} to='periodeStart' style={{fontSize:'.9em', padding:'0 0 0 .9em'}}>Periode</Label>
							<Col xs={4}>
								<Input bsSize='sm' id='periodeStart' name='periodeStart' type="date" />
							</Col>
							<Col xs='1' className='px-0 text-center'><p>s/d</p></Col>
							<Col xs={4}>
								<Input bsSize='sm' id='periodeEnd' name='periodeEnd' type="date" />
							</Col>
						</FormGroup>
					</Container>
				</section>

				<Container>
					<Row>
						<Col>
							<div style={boxStyle}>
								<p><b>Total Penjualan</b></p>
								<h4 className='m-0'>Rp 23,000,000,000</h4>
							</div>
						</Col>
					</Row>
				</Container>

				<section style={section2style}>
					<Container className='mt-5'>
						<Search placeholder='cari nama mitra'/>
						<Table className='text-center text-muted'>
							<thead>
				        <tr>
				          <th>mitra</th>
				          <th>total penjualan <Order onClick={()=>console.log('order asc/desc')} /></th>
				        </tr>
				      </thead>
				      <tbody>
				      	{tableDatas.map(dat=>{
				      		return <tr key={dat.mitra}>
					          <th scope="row">{dat.mitra}</th>
					          <th scope="row">{dat.totalPenjualan}</th>
					        </tr>
				      	})}
				      </tbody>
						</Table>
					</Container>
				</section>

				<div className='space'></div>
			</div>
		</Fragment>
	)
}
export default LaporanMitraPenjualan;