import {Component} from 'react';
import Popup from '../../../component/Popup/Popup';
import {Container, Row, Col, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';

export default class InputSetoran extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData:{ 
				tanggal:null,
				totalOmzetPenjualan:'', 
				totalPcsSetoran:'', 
				totalSetoran:''
			}
		}
	}
	handleData = e => {
		let keys = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[keys]: value
			}
		})
	}
	handleSubmit = (e) => {
		e.preventDefault()
		this.props.close('ok')
	}
	render(){
		return(
			<Popup close={this.props.close}>
				<h4 className="text-center">Kirim Setoran</h4>
					<Container>
						<Row>
							<Col>
								<FormGroup>
									<Label for="tanggal">Tanggal</Label>
									<Input type="date" name="tanggal" id="tanggal" onChange={this.handleData} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col xs="6">
								<FormGroup>
									<Label for="totalOmzetPenjualan">Total Omzet Penjualan</Label>
									<InputGroup>
								        <InputGroupAddon addonType="prepend">
								        	<InputGroupText>Rp.</InputGroupText>
								        </InputGroupAddon>
										<Input type="number" name="totalOmzetPenjualan" id="totalOmzetPenjualan" onChange={this.handleData} />
								    </InputGroup>
								</FormGroup>
							</Col>
							<Col xs="6">
								<FormGroup>
									<Label for="totalPcsSetoran">Total Pcs Terjual</Label>
									<Input type="number" name="totalPcsSetoran" id="totalPcsSetoran" onChange={this.handleData} />
								</FormGroup>
							</Col>
						</Row>
						<FormGroup>
							<Label for="totalSetoran">Total Setoran</Label>
							<InputGroup>
						        <InputGroupAddon addonType="prepend">
						            <InputGroupText>Rp.</InputGroupText>
						        </InputGroupAddon>
						        <Input type="number" name="totalSetoran" id="totalSetoran" onChange={this.handleData} />
					        </InputGroup>
						</FormGroup>
						<button className="btn btn-block btn-success" onClick={this.handleSubmit}>SETOR SEKARANG</button>
					</Container>
			</Popup>
		)
	}
}