import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Filter as FilterSvg } from '../../../component/ImageSvg/ImageSvg';
import History  from './History';
import Filter from './Filter';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const historys = [
	{id:0, text:['2312','stok retail','14 April 2021','1/2 paket','done']},
	{id:1, text:['2313','stok retail','15 April 2021','1/2 paket','done']},
	{id:2, text:['2314','stok retail','16 April 2021','1/2 paket','process']},
	{id:3, text:['2315','stok retail','17 April 2021','1/2 paket','process']},
	{id:4, text:['2316','stok retail','18 April 2021','1/2 paket','process']},
]

const Permintaan = () => {
	let [ section2style, setSection2style ] = useState({})
	let [ isActiveFilter, setIsActiveFilter ] = useState(false)

	let filterHandle = () => {
		setIsActiveFilter(!isActiveFilter)
	}
	ChangeTitle({title:'Permintaan Stok | Permintaan'})
	useEffect(()=>{
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2style({marginTop:'2em', minHeight:String(section2height)+'px'})
	}, [])
	return (
		<div  style={{background:'#fff',padding:'15px 0'}}>
			{isActiveFilter?<Filter close={filterHandle} />:null}
			<section>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Permintaan Stok</h1>
				<hr style={{margin:'0 auto',width:'86%'}} />
				<Container className="text-center mt-2">
					<Row className="ml-2 mr-2 stok-nav">
						<Col xs="6 p-0">
							<Link to="/permintaan-stok/permintaan">Permintaan</Link>
							<hr className="active"/>
						</Col>
						<Col xs="6 p-0">
							<Link to="/permintaan-stok/retur">Retur/Buang</Link>
							<hr/>
						</Col>
					</Row>
				</Container>
			</section>

			<section style={section2style}>
				<Container>
					<div className="text-right" style={{margin:'1em 1em 0 0'}}>
						<FilterSvg fill="black" filterClick={filterHandle} /> 
						<hr className='my-2 w-100'/>
					</div>
					<p className="ml-3 mt-1 text-center"><b>Riwayat Setoran</b></p>
					<hr style={{width:'96%', borderTop:'.25px solid grey', margin:'10px'}}/>
					{historys.map(history=>{
						return <History key={history.id} text={history.text} />
					})}
				</Container>
				<div className='space'></div>
			</section>
		</div>
	)
}
export default Permintaan;