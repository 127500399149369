import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import History  from './History';
import KirimStok from './KirimStok';
import TolakStok from './TolakStok';
import { SearchWithFilter } from '../../../component/Search/Search';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const historys = [
			{id:1, text:['No. 12345', 'stok retail', '14 April 2021', '1/2 paket']},
			{id:2, text:['No. 12346', 'stok retail', '16 April 2021', '1 paket']},
			{id:3, text:['No. 12347', 'stok retail', '17 April 2021', '2 paket']},
			{id:4, text:['No. 12348', 'stok retail', '18 April 2021', '1 paket']},
			{id:5, text:['No. 12349', 'stok retail', '19 April 2021', '1/2 paket']},
		]

const UangMasuk = () => {
	let [ section2style, setSection2style ] = useState({})
	let [ isActiveKirimStok, setIsActiveKirimStok ] = useState(false)
	let [ isActiveTolakStok, setIsActiveTolakStok ] = useState(false)

	let handleKirimStok = () => {
		setIsActiveKirimStok(!isActiveKirimStok)
	}
	let handleTolakStok = () => {
		setIsActiveTolakStok(!isActiveTolakStok)
	}
	ChangeTitle({title:'Permintaan Stok | Permintaan'})
	useEffect(()=>{
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2style({paddingTop:'.2em', marginTop:'.3em', background:'white', borderRadius:'1.3em 1.3em 0 0', minHeight:String(section2height)+'px'})
	}, [])
	return (
		<div>
			{isActiveKirimStok?<KirimStok close={handleKirimStok}/>:null}
			{isActiveTolakStok?<TolakStok close={handleTolakStok}/>:null}
			<section style={{borderRadius:'0 0 1.3em 1.3em', background:'white', paddingBottom:'1.3em'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Permintaan Stok</h1>
				<hr style={{margin:'0 auto',width:'86%'}} />
				<Container className="text-center mt-2">
					<Row className="ml-2 mr-2 stok-nav">
						<Col xs="6 p-0">
							<Link to="/permintaan-stok/permintaan">Permintaan</Link>
							<hr className="active"/>
						</Col>
						<Col xs="6 p-0">
							<Link to="/permintaan-stok/retur">Retur Buang</Link>
							<hr/>
						</Col>
					</Row>
				</Container>
			</section>

			<section style={section2style}>
				<Container className="mt-4">
					<SearchWithFilter />
					<p className="ml-3 mt-2 text-center"><b>Riwayat Permintaan</b></p>
					<hr style={{width:'96%', borderTop:'.25px solid grey', margin:'10px'}}/>
					{historys.map(history=>{
						return <History key={history.id} text={history.text} kirim={handleKirimStok} tolak={handleTolakStok}/>
					})}
				</Container>
			</section>

			<div className="space"></div>
		</div>
	)
}
export default UangMasuk;