import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import MenuList from '../../../component/MenuList/MenuList';
import TambahProduk from './TambahProduk';
import PeriodeForm from '../../../component/PeriodeForm/PeriodeForm';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

let historys = [
	{id:0, text:['14 April 2021', 'Produksi 100 paket', '14/04/21', 5000]},
	{id:1, text:['15 April 2021', 'Produksi 110 paket', '15/04/21', 5000]},
	{id:2, text:['16 April 2021', 'Produksi 120 paket', '16/04/21', 5000]},
	{id:3, text:['17 April 2021', 'Produksi 130 paket', '17/04/21', 5000]},
	{id:4, text:['18 April 2021', 'Produksi 150 paket', '18/04/21', 5000]},
]

const Dashboard = () => {
	let [ section2Style, setSection2Style ] = useState({})
	let [ isActiveTambahProduk, setIsActiveTambahProduk ] = useState(false)

	let handleTambahProduk = () => {
		setIsActiveTambahProduk(!isActiveTambahProduk)
	}
	let menuList = [
		{id:0, text:'Input Produksi', link:"#", click:handleTambahProduk},
		{id:1, text:'Laporan Produksi', link:"/laporan-mitra"},
		{id:2, text:'Data Supplier', link:"/data-supplier"},
	]
	ChangeTitle({title:'Dashboard'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementById('fillKurir').getBoundingClientRect().top
		setSection2Style({minHeight:String(section2height)+'px'})
	}, [])
	return (
	<div style={{background:'var(--abu-abu0)'}}>
		{isActiveTambahProduk?<TambahProduk close={handleTambahProduk} />:null}
		<section style={{ background:'#fff', padding:'.2em 0 1em', borderRadius:'0 0 25px 25px', marginBottom:'10px'}}>
			<Container>
				<PeriodeForm />
				<hr/>
				<div>
					<h3 className="mb-0 mt-2 text-blue">10</h3>
					<p>Total Mitra Binaan</p>
				</div>
			</Container>
		</section>

		<section className='sec'>
			<Container>
				<Row>
					{menuList.map(menu=>{
						return <MenuList key={menu.id} link={menu.link} text={menu.text} click={menu.click} />
					})}
				</Row>
			</Container>
		</section>

		<Container style={section2Style} className='mt-4' id="fillKurir">
			<p><b>Riwayat Data Pengiriman</b></p>
			<hr className='w-100 my-1'/>
			{historys.map(history=>{
				return <History key={history.id} text={history.text} />
			})}
		</Container>

		<div className="space"></div>
	</div>
	)
}
export default Dashboard;

const History = ({text}) => {
	return (
		<Row>
			<Col xs='4'>
				<p><b>{text[0]}</b></p>
				<p>{text[1]}</p>
			</Col>
			<Col xs={{size:4, offset:4}}>
				<p><b>kode. {text[2]}</b></p>
				<p>{text[3]} pcs</p>
			</Col>
			<hr className='w-100 my-1'/>
		</Row>
	)
}
