import { Fragment, useState, createContext, useReducer } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Header, Footer, Sidebar } from './pages/template/template';
import './App.css';

import AppPembeli from './pages/Pembeli/AppPembeli';
import AppMitra from './pages/Mitra/AppMitra';
import AppWilayah from './pages/Wilayah/AppWilayah';
import AppKurir from './pages/Kurir/AppKurir';
import AppRumahProduksi from './pages/RumahProduksi/AppRumahProduksi';
import AppPusat from './pages/Pusat/AppPusat';

import YukBacaSholawat from './pages/All/YukBacaSholawat';

// Context
export const Context = createContext()

const initialState = {
  userGroup: '',
  isActiveBottomNav: true,
}

const reducer = (state, action) => {
  switch(action.type) {
    case "LOGIN":
      return {
        ...state,
        userGroup: action.group
      }
    case "LOGOUT":
      return {
        ...state,
        userGroup: ''
      }
    case "TOGGLE_BOTTOM_NAV":
    	return {
    		...state,
    		isActiveBottomNav: !state.isActiveBottomNav
    	}
    case "TEST":
      console.log('test reducer')
      break;
    default:
      return state
  }
}

function App() {
  const [ state, dispatch ] = useReducer(reducer, initialState)

	const [ isActiveBars, setIsActiveBars ] = useState(false);
  const toggleBar = () => {
    setIsActiveBars( !isActiveBars )
  }
  return (
    <Fragment>
      <BrowserRouter>
	      <Context.Provider value={{state, dispatch}}>
          <div style={{maxWidth:'600px', margin:'0 auto'}}>
            <Switch>
              <Route path="/yuk-baca-sholawat">
                <YukBacaSholawat />
              </Route>

            </Switch>
  	        <Header burgerClick={ toggleBar } />
  	        {state.isActiveBottomNav? <Footer />: null}
  	        <Sidebar close={ toggleBar } isActive={ isActiveBars } />

  	        {(state.userGroup==='')? <AppPembeli />: null}
  	        {(state.userGroup==='mitra')? <AppMitra />: null}
            {(state.userGroup==='wilayah')? <AppWilayah />: null}
            {(state.userGroup==='kurir')? <AppKurir />: null}
            {(state.userGroup==='rumahproduksi')? <AppRumahProduksi />: null}
            {(state.userGroup==='pusat')? <AppPusat />: null}
	        </div>
	      </Context.Provider>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;

