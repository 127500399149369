import { Component } from 'react'
import { Container, Row, Col, FormGroup, Label, Input, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';

export default class TanggapiRetur extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData:{
				kodeProduksi: ''
			}
		}
	}
	closePopup = () => {
		this.props.close()
	}
	handleData = e => {
		let keys = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[keys]: value
			}
		},()=>{
			console.log(this.state.formData)
		})
	}
	handleSubmit = e => {
		e.preventDefault()
		if(e.target.name==='close'){
			this.props.history.goBack()
		}
	}
	componentDidMount=()=>{
		document.getElementsByTagName('nav')[0].style.display = 'none';
		this.setState({
			formData:{
				...this.state.formData,
				kodeProduksi: this.props.match.params.noRetur
			}
		})
	}
	componentWillUnmount=()=>{
		document.getElementsByTagName('nav')[0].style.display = 'block'
	}
	render(){
		return(
			<div style={{background:'var(--abu-abu0)'}}>
				<h4 className="text-center">TERIMA STOK</h4>
					<Container>
						<FormGroup>
							<Row>
								<Col className="pr-1">
									<Label for="tanggal">Tanggal</Label>
									<Input id="tanggal" name="tanggal" type="date" onChange={this.handleData}/>
								</Col>
							</Row>
						</FormGroup>
						<FormGroup>
							<Row>
								<Col xs="6">
									<Label for="kurir">Kurir</Label>
									<Input id="kurir" name="kurir" type="text" onChange={this.handleData}/>
								</Col>
								<Col xs="6">
									<Label for="kodeProduksi">Kode Produksi</Label>
									<Input id="kodeProduksi" name="kodeProduksi" type="text" value={this.state.formData.kodeProduksi} onChange={this.handleData}/>
								</Col>
							</Row>
						</FormGroup>
						<p className="text-center mt-3">Kondisi Paket</p>
						{
							[['Dawet','liter'],['Creamer','pack'],['Gula','kilo'],['Cup+tutup','pack'],['Sendok','pack'],['Plastik 1','Pack'],['Plastik 2','Pack'],['Plastik Besar','Pack'],['Sedotan','Pack'],['Brosur','lmbar'],['Es','kilo']].map(props=>{
								return <FormTambahan key={props[0]} name={props[0]} satuan={props[1]} onChange={this.handleData} />
							})
						}
						<button onClick={event=>this.handleSubmit(event)} name="terima" className="btn btn-block btn-success mt-4">TERIMA</button>
						<button onClick={event=>this.handleSubmit(event)} name="close" className="btn btn-block btn-danger mt-2">CLOSE</button>
					</Container>
				<div className='space'></div>
			</div>
		)
	}
}

const FormTambahan = ({name, satuan, onChange}) => {
	return(
		<FormGroup>
			<Row>
				<Col xs="3" className="mt-4">
					<p>{name}</p>
				</Col>
				<Col xs="3 pr-1">
					<Label for={name+"Datang"} className="mb-0">Datang</Label>
					<InputGroup>
						<Input id={name+"Datang"} name={name+"Datang"} type="number" onChange={onChange}/>
						<InputGroupAddon addonType="append">
				        	<InputGroupText className="p-0">{satuan}</InputGroupText>
				        </InputGroupAddon>
			        </InputGroup>
				</Col>
				<Col xs="3 pl-1 pr-1">
					<Label for={name+"Diterima"} className="mb-0">Diterima</Label>
					<InputGroup>
						<Input id={name+"Diterima"} name={name+"Diterima"} type="number" onChange={onChange}/>
						<InputGroupAddon addonType="append">
				        	<InputGroupText className="p-0">{satuan}</InputGroupText>
				        </InputGroupAddon>
				    </InputGroup>
				</Col>
				<Col xs="3 pl-1">
					<Label for={name+"Reject"} className="mb-0">Reject</Label>
					<InputGroup>
						<Input id={name+"Reject"} name={name+"Reject"} type="number" onChange={onChange}/>
						<InputGroupAddon addonType="append">
				        	<InputGroupText className="p-0">{satuan}</InputGroupText>
				        </InputGroupAddon>
				    </InputGroup>
				</Col>
				<hr/>
			</Row>
		</FormGroup>
	)
}