import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import History  from './History';
import Search from '../../../component/Search/Search';
import TerimaConfirm from './TerimaConfirm';
import PeriodeForm from '../../../component/PeriodeForm/PeriodeForm';
import { ChangeTitleEslint } from '../../../component/ChangeTitle/ChangeTitle';

const historys = [
	{id:0, text:['14 April 2021','2312','2.500.000','kode mitra', true]},
	{id:1, text:['15 April 2021','2313','2.500.000','kode mitra', true]},
	{id:2, text:['16 April 2021','2314','2.500.000','kode mitra', true]},
	{id:3, text:['17 April 2021','2315','2.500.000','kode mitra', true]},
	{id:4, text:['18 April 2021','2316','2.500.000','kode mitra', true]},
]

const Permintaan = () => {
	let [ section2style, setSection2style ] = useState({})
	let [ isActiveConfirm, setIsActiveConfirm ] = useState(false)
	let handleConfirm = () => {
		setIsActiveConfirm(!isActiveConfirm)
	}
	ChangeTitleEslint({title:'Permintaan Stok | Permintaan'})
	useEffect(()=>{
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2style({marginTop:'1.5em', minHeight:String(section2height)+'px'})
	}, [])
	return (
		<div  style={{background:'#fff',padding:'15px 0'}}>
			{isActiveConfirm?<TerimaConfirm close={handleConfirm} />:null}
			<section>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>Permintaan Stok</h1>
				<hr style={{margin:'0 auto',width:'86%'}} />
				<Container className="text-center mt-2">
					<Row className="ml-2 mr-2 stok-nav">
						<Col xs="6 p-0">
							<Link to="/pengambilan-setoran/diminta">Permintaan</Link>
							<hr className="active"/>
						</Col>
						<Col xs="6 p-0">
							<Link to="/pengambilan-setoran/retur">Retur/Buang</Link>
							<hr/>
						</Col>
					</Row>
					<PeriodeForm className='clearfix mt-2' />
				</Container>
			</section>

			<section style={section2style}>
				<Container>
					<Search />
					<p className="ml-3 mt-1 text-center"><b>Riwayat Setoran</b></p>
					<hr style={{width:'96%', borderTop:'.25px solid grey', margin:'10px'}}/>
					{historys.map(history=>{
						return <History key={history.id} text={history.text} proses={handleConfirm} />
					})}
				</Container>
				<div className='space'></div>
			</section>
		</div>
	)
}
export default Permintaan;