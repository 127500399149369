import { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import Popup from '../../../component/Popup/Popup';

const InputPengeluaran = ({close}) => {
	let [ formData, setFormData ] = useState({tanggal:'', number:'', textarea:''})
	let handleForm = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}
	let submit = () => {
		console.log(formData)
		close()
	}
	return (
		<Popup close={close}>
			<div style={{padding:'1em'}}>
				<h5 style={{padding:'1em', textAlign:'center'}}>INPUT PENGELUARAN</h5>
				<FormGroup>
					<Label to="tanggal">Tanggal</Label>
					<Input id="tanggal" name="tanggal" type="date" onChange={handleForm} />
				</FormGroup>
				<FormGroup>
					<Label to="jumlah">Jumlah</Label>
					<Input id="jumlah" name="jumlah" type="number" onChange={handleForm} />
				</FormGroup>
				<FormGroup>
					<Label to="tujuan">Tujuan</Label>
					<Input id="tujuan" name="tujuan" type="textarea" onChange={handleForm} />
				</FormGroup>
				<button className="btn btn-success btn-block" onClick={submit} name='sudah'>INPUT</button>
			</div>
		</Popup>
	)
}

export default InputPengeluaran;