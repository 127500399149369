import { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom';

import Dashboard from './Dashboard/Dashboard';
import Profile from './Profile/Profile';
import DataHpp from './DataHpp/DataHpp';
import Diterima from './TerimaUangPenjualan/Diterima';
import Proses from './TerimaUangPenjualan/Proses';
import UangMasuk from './KeuanganPusat/UangMasuk';
import UangKeluar from './KeuanganPusat/UangKeluar';
import LaporanWilayah from './LaporanWilayah/LaporanWilayah';
import SettingHargaJual from './SettingHargaJual/SettingHargaJual';
import SettingPaketAcara from './SettingPaketAcara/SettingPaketAcara';
import SettingPaketRetail from './SettingPaketRetail/SettingPaketRetail';
import LaporanMitraPenjualan from './LaporanMitraPenjualan/LaporanMitraPenjualan';

const AppPusat = () => {
	return (
		<Fragment>
			<Switch>
				<Route path="/setting-paket-retail" exact component={ SettingPaketRetail } />
				<Route path="/setting-paket-acara" exact component={ SettingPaketAcara } />
				<Route path="/setting-harga-jual" exact component={ SettingHargaJual } />
				
				<Route path="/laporan-mitra-penjualan" exact component={ LaporanMitraPenjualan } />
				<Route path="/laporan-wilayah" exact component={ LaporanWilayah } />

				<Route path="/keuangan-pusat/uang-keluar" exact component={ UangKeluar } />
				<Route path="/keuangan-pusat/uang-masuk" exact component={ UangMasuk } />
				<Route path="/terima-uang-penjualan/proses" exact component={ Proses } />
				<Route path="/terima-uang-penjualan/diterima" exact component={ Diterima } />
				<Route path="/data-hpp" exact component={ DataHpp } />

				<Route path="/profile" exact component={ Profile } />
				<Route path="/" exact component={ Dashboard } />
			</Switch>
		</Fragment>
	)
}
export default AppPusat;