import {Col} from 'reactstrap';
import {Link} from 'react-router-dom';

import './MenuList.css'

const MenuList = ({link, text, click}) => {
	return(
		<Col className="menu-item p-0" xs="3" onClick={click}>
			<Link to={link}>
				<div className="menu"></div>
				<p>{text}</p>
			</Link>
		</Col>
	)
}

export default MenuList;