import Popup from '../../../component/Popup/Popup';

const TerimaConfirm = ({close}) => {
	return (
		<Popup close={close}>
			<div style={{padding:'1em'}}>
				<h5 style={{padding:'1em 1em 2em'}}>Apakah anda sudah menerima setoran dari Mitra ?</h5>
				<button className="btn btn-success btn-block" onClick={close} name='sudah'>SUDAH</button>
				<button className="btn btn-danger btn-block" onClick={close} name='belum' >BELUM</button>
			</div>
		</Popup>
	)
}

export default TerimaConfirm;