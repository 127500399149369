import { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Table } from 'reactstrap';
import Search from '../../../component/Search/Search';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const tableDatas = [
		{mitra:'01', paket:1, cup:50, penjualan:20, sisa:30},
		{mitra:'02', paket:1, cup:50, penjualan:20, sisa:30},
		{mitra:'03', paket:1, cup:50, penjualan:20, sisa:30},
		{mitra:'04', paket:1, cup:50, penjualan:20, sisa:30},
		{mitra:'05', paket:1, cup:50, penjualan:20, sisa:30},
		{mitra:'06', paket:1, cup:50, penjualan:20, sisa:30},
		{mitra:'07', paket:1, cup:50, penjualan:20, sisa:30},
		{mitra:'08', paket:1, cup:50, penjualan:20, sisa:30},
		{mitra:'09', paket:1, cup:50, penjualan:20, sisa:30},
	]

const LaporanMitra = () => {
	let [ section2style, setSection2Style ] = useState({});
	
	ChangeTitle({title:'Laporan Produksi'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2Style({padding:'15px 0', margin:'10px', minHeight:String(section2height)+'px'})
	}, [])
	let boxStyle = {background:'var(--green)', borderRadius:'1.2em', color:'white', padding:'1em', minHeight:'6.6em'}
	return(
		<Fragment>
			<div style={{background:'white'}}>
				<section style={{padding:'15px 0', marginBottom:'10px'}}>
					<h4 className="text-center text-blue">Laporan Produksi</h4>
					<Container>
						<hr className='mb-2 w-100'/>
						<FormGroup row>
							<Label xs={3} to='kodeProduksi' style={{fontSize:'.9em', padding:'0 0 0 .9em'}}>Pilih Kode Produksi</Label>
							<Col xs={9}>
								<Input bsSize='sm' id='kodeProduksi' name='kodeProduksi' type="text" />
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label xs={3} to='periodeStart' style={{fontSize:'.9em', padding:'0 0 0 .9em'}}>Periode</Label>
							<Col xs={4}>
								<Input bsSize='sm' id='periodeStart' name='periodeStart' type="date" />
							</Col>
							<Col xs='1' className='px-0 text-center'><p>s/d</p></Col>
							<Col xs={4}>
								<Input bsSize='sm' id='periodeEnd' name='periodeEnd' type="date" />
							</Col>
						</FormGroup>
					</Container>
				</section>

				<Container>
					<Row>
						<Col className='text-center'>
							<div style={boxStyle}>
								<div className='my-auto d-block'>
									<p><b>Total Cup</b></p>
									<h4 className='m-0'>30</h4>
								</div>
							</div>
						</Col>
						<Col className='text-center'>
							<div style={boxStyle}>
								<p><b>Total Penjualan</b></p>
								<h4 className='m-0'>30</h4>
							</div>
						</Col>
						<Col className='text-center'>
							<div style={boxStyle}>
								<p><b>Total Sisa</b></p>
								<h4 className='m-0'>30</h4>
							</div>
						</Col>
					</Row>
				</Container>

				<section style={section2style}>
					<Container className='mt-2'>
						<Search />
						<Table>
							<thead>
				        <tr>
				          <th>mitra</th>
				          <th>paket</th>
				          <th>cup</th>
				          <th>penjualan</th>
				          <th>sisa</th>
				        </tr>
				      </thead>
				      <tbody>
				      	{tableDatas.map(dat=>{
				      		return <tr key={dat.mitra}>
					          <th scope="row">{dat.mitra}</th>
					          <td>{dat.paket}</td>
					          <td>{dat.cup}</td>
					          <td>{dat.penjualan}</td>
					          <td>{dat.sisa}</td>
					        </tr>
				      	})}
				      </tbody>
						</Table>
					</Container>
				</section>

				<div className='space'></div>
			</div>
		</Fragment>
	)
}
export default LaporanMitra;