import { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './Dashboard/Dashboard';
import SearchMitra from './SearchMitra/SearchMitra';
import Informasi from './Informasi/Informasi';
import TheArticle from './TheArticle/TheArticle';

import SplashScreen from '../All/SplashScreen';

const AppPembeli = () => {
	return (
		<Fragment>
			<Switch>
				<Route path="/search-mitra" exact component={ SearchMitra } />
				<Route path="/informasi" exact component={ Informasi } />
				<Route path="/informasi/:article" exact component={ TheArticle } />
				<Route path="/splash-screen" exact component={ SplashScreen } />
				<Route path="/" exact component={ Dashboard } />
			</Switch>
		</Fragment>
	)
}

export default AppPembeli;