import {Component, Fragment} from 'react';
import {Container} from 'reactstrap';
import {SetorWilayahHistory} from '../../../component/History/History';
import InputSetoran from './InputSetoran';
import {Filter as FilterSvg} from '../../../component/ImageSvg/ImageSvg';
import Alert from '../../../component/Alert/Alert';
import Filter from './Filter';
import Pagination from '../../../component/Pagination/Pagination';

export default class SetorWilayah extends Component{
	state = {
		isActiveInputSetoran: false,
		isActiveFilter: false,
		isActiveAlert: false,
		historys: [
			{id:0, text:['14 April 2021', 'no. Setor 2313', 'Rp, 250.000', 'request']},
			{id:1, text:['30 Maret 2021', 'no. Setor 2312', 'Rp, 3.250.000', 'process']},
			{id:2, text:['15 Maret 2021', 'no. Setor 2311', 'Rp, 25.642.000', 'done']},
			{id:3, text:['10 Maret 2021', 'no. Setor 2309', 'Rp, 25.452.100', 'request']},
		],
		section2style: {}
	}
	handleInputSetoran = (data) => {
		if(data==='ok'){
			this.setState({
				isActiveAlert: !this.state.isActiveAlert,
				isActiveInputSetoran: !this.state.isActiveInputSetoran
			})
		}
		this.setState({
			isActiveInputSetoran: !this.state.isActiveInputSetoran
		})
	}
	filterHandle = data => {
		console.log(data)
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}
	alertToggle = () => {
		this.setState({
			isActiveAlert: !this.state.isActiveAlert
		})
	}
	componentDidMount = () => {
		document.title = "Setor Wilayah"
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		this.setState({
			...this.state,
			section2style:{padding:'15px 0', margin:'10px 10px', minHeight:String(section2height)+'px'}
		})
	}
	componentWillUnmount = () => {
		document.title = "Dashboard"
	}
	render(){
		return(
		<Fragment>
			{this.state.isActiveAlert?<Alert text="selamat info setoran anda telah masuk mohon menunggu untuk pengambilan dari kurir" close={this.alertToggle} />:null}
			{this.state.isActiveInputSetoran?<InputSetoran close={data=>this.handleInputSetoran(data)} />:null}
			{this.state.isActiveFilter?<Filter close={data=>this.filterHandle(data)} />:null}
			
			<div style={{background:'white'}}>

				<section style={{padding:'15px 0 1px', marginBottom:'10px'}}>
					<h4 className="text-center">Penyetoran Wilayah</h4>
					<hr/>
				</section>

				<button onClick={this.handleInputSetoran} className="btn btn-success btn-block" style={{width:'80%', padding: '10px', margin:'0 auto'}}>Input Setoran</button>

				<section style={this.state.section2style}>
					<Container>
						<p className="ml-3 mt-1"><b>Riwayat Setoran</b></p>
						<div className="text-right" style={{margin:'1em 1em 0 0'}}>
							<FilterSvg fill="black" filterClick={this.filterHandle} /> 
							<hr className='my-2 w-100'/>
						</div>
						{this.state.historys.map(history=>{
							return <SetorWilayahHistory key={history.id} text={history.text} />
						})}
					</Container>
					<Pagination count={4} go={num=>console.log(num)} active={2}/>
					<div className="space"></div>
				</section>
			</div>
		</Fragment>
		)
	}
}