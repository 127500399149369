import { Row, Col } from 'reactstrap';

const History = ({text}) => {

	let status = ''
	switch(text[4]){
		case 'request':
			status = <p className="mt-2 text-center text-danger"><strong>{text[4]}</strong></p>
			break;
		case 'process':
			status = <p className="mt-2 text-center text-warning"><strong>{text[4]}</strong></p>
			break;
		case 'done':
			status = <p className="mt-2 text-center text-primary"><strong>{text[4]}</strong></p>
			break;
		default:
			break;
	}

	return(
		<Row className="ml-2 mr-2 pb-2 mt-3 text-center" style={{borderBottom:'1px solid grey'}}>
			<Col xs="4">
				<strong>No. {text[0]}</strong>
				<p className="text-muted">{text[1]}</p>
			</Col>
			<Col xs="4">
				<strong>No. {text[2]}</strong>
				<p className="text-muted">{text[3]}</p>
			</Col>
			<Col xs="4" className="font-weight-bold text-center">
				{status}
			</Col>
		</Row>
	)
}

export default History;