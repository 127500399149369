import { useState } from 'react';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import Popup from '../../../component/Popup/Popup';

const KirimStok = ({close}) => {
	let [ formData, setFormData ] = useState({tanggal:'', nomorPermintaan:'', jenisPaket:'', kategori:'', kodeProduksi:''})
	let handleForm = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}
	let submit = () => {
		console.log(formData)
		close()
	}
	return (
		<Popup close={close}>
			<div style={{padding:'1em'}}>
				<h5 style={{padding:'1em', textAlign:'center'}}>KIRIM STOK</h5>
				<FormGroup row>
					<Col>
						<Label to="tanggal">Tanggal Permintaan</Label>
						<Input id="tanggal" name="tanggal" type="date" onChange={handleForm} />
					</Col>
					<Col>
						<Label to="nomorPermintaan">Nomor Permintaan</Label>
						<Input id="nomorPermintaan" name="nomorPermintaan" type="number" onChange={handleForm} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Col>
						<Label to="jenisPaket">Jenis Paket</Label>
						<Input id="jenisPaket" name="jenisPaket" type="text" onChange={handleForm} />
					</Col>
					<Col>
						<Label to="kategori">Kategori</Label>
						<Input id="kategori" name="kategori" type="select" onChange={handleForm}>
							<option value="stok retail">stok retail</option>
							<option value="stok retail2">stok retail2</option>
							<option value="stok retail3">stok retail3</option>
						</Input>
					</Col>
				</FormGroup>
				<FormGroup>
					<Label to="kodeProduksi">Kode Produksi</Label>
					<Input id="kodeProduksi" name="kodeProduksi" type="text" onChange={handleForm} />
				</FormGroup>
				<button className="btn btn-success btn-block" onClick={submit} name='sudah'>Kirim</button>
			</div>
		</Popup>
	)
}

export default KirimStok;