import { Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const History = ({text, kirim, tolak}) => {
	return(
		<Row className="ml-2 mr-2 pb-3 mt-3" style={{borderBottom:'1px solid grey'}}>
			<Col xs="4" style={{marginTop:'.5em'}}>
				<strong style={{fontSize:'1.1em'}}>{text[0]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}}>{text[1]}</p>
			</Col>
			<Col xs="4" style={{marginTop:'.5em'}}>
				<strong style={{fontSize:'1.1em'}}>{text[2]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}}>{text[3]}</p>
			</Col>
			<Col xs="4">
				<Button color="primary" block size="sm" onClick={kirim} style={{borderRadius:'1em'}}>Kirim Stok</Button>
				<Button color="danger" block size="sm" onClick={tolak} style={{borderRadius:'1em'}}>Tolak</Button>
			</Col>
		</Row>
	)
}
export default History;

export const ReturBuangHistory = ({text, link, buang}) => {
	let button = {}
	if(text[4]==='Tanggapi Retur'){
		button = <Link className='btn btn-danger btn-block btn-sm' style={{borderRadius:'1em'}} to={link}>{text[4]}</Link>
	}else{
		button = <Link className='btn btn-block btn-sm font-weight-bold text-danger' style={{borderRadius:'1em'}} onClick={buang} to='#'>{text[4]}</Link>
	}
	return(
		<Row className="ml-2 mr-2 pb-3 mt-3" style={{borderBottom:'1px solid grey'}}>
			<Col xs="4" style={{marginTop:'.5em'}}>
				<strong style={{fontSize:'1.1em'}}>No. {text[0]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}}>{text[1]}</p>
			</Col>
			<Col xs="4" style={{marginTop:'.5em'}}>
				<strong style={{fontSize:'1.1em'}}>{text[2]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}}>{text[3]}</p>
			</Col>
			<Col xs="4">
				{button}
			</Col>
		</Row>
	)
}