import { Button, Row, Col } from 'reactstrap';

const History = ({text, proses}) => {

	let status = ''
	switch(text[4]){
		case true:
			status = <Button size='sm' color='success' block style={{borderRadius:'1em'}} onClick={proses}>proses</Button>
			break;
		case false:
			status = <Button size='sm' color='secondary' block style={{borderRadius:'1em'}} disabled>proses</Button>
			break;
		default:
			break;
	}

	return(
		<Row className="ml-2 mr-2 pb-2 mt-3 text-center" style={{borderBottom:'1px solid grey'}}>
			<Col xs="4">
				<strong>{text[0]}</strong>
				<p className="text-muted">No. Setor {text[1]}</p>
			</Col>
			<Col xs="4">
				<strong>Rp {text[2]}</strong>
				<p className="text-muted">{text[3]}</p>
			</Col>
			<Col xs="4" className="font-weight-bold text-center">
				{status}
			</Col>
		</Row>
	)
}

export default History;