import { useContext, useState, useEffect} from 'react'
import {Container, Row, Col, FormGroup, Label, Input} from 'reactstrap';
import { Context } from '../../../App';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const TambahPenjualan = props => {
	let [ fillStyle, setFillStyle ] = useState({})
	let { dispatch } = useContext(Context)
	let [state, setState] = useState({
		formData:{
			tanggal: null,
			noPenjualan: null,
			namaPembeli: null,
			kontak: null,
			kategoriPenjual: null,
			jumlah: null, 
			hargaTotalPenjualan: null,
			uangDiterima: null,
			uangDikembalikan: null
		}
	})
			
	let handleData = (e) => {
		setState({
			formData:{
				...state.formData,
				[e.target.name]: e.target.value,
			}
		})
	}
	let handleSubmit = (e) => {
		e.preventDefault()
		props.history.goBack()
	}

	ChangeTitle({title:'Penjualan | Tambah Penjualan '})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementById('mitraPenjualanHarianTambahplnm').getBoundingClientRect().top
		setFillStyle({minHeight:String(section2height)+'px'})
	}, [])

	useEffect(()=>{
		dispatch({type:"TOGGLE_BOTTOM_NAV"})
		return () => {
			dispatch({type:"TOGGLE_BOTTOM_NAV"})
		}
		// eslint-disable-next-line 
	}, [])
	 
	return (
		<div style={{background:'var(--abu-abu0)'}}>
			<section style={{padding:'15px 0'}}>
				<h1 style={{textAlign:'center',fontSize:'1.5em',color:'#150d56'}}>TAMBAH PENJUALAN</h1>
				<hr style={{width:'90%', margin:'0 auto'}} />
			</section>
			<Container id='mitraPenjualanHarianTambahplnm' style={fillStyle}>
				<Row>
					<Col xs="6">
						<FormGroup>
							<Label for="tanggal">Tanggal</Label>
							<Input type="date" name="tanggal" id="tanggal" onChange={handleData} />
						</FormGroup>
					</Col>
					<Col xs="6">
						<FormGroup>
							<Label for="no-penjualan">Nomor Penjualan</Label>
							<Input type="text" name="noPenjualan" id="no-penjualan" onChange={handleData} />
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col xs="6">
						<FormGroup>
							<Label for="nama-pembeli">Nama Pembeli</Label>
							<Input type="text" name="namaPembeli" id="nama-pembeli" onChange={handleData} />
						</FormGroup>
					</Col>
					<Col xs="6">
						<FormGroup>
							<Label for="kontak">Kontak</Label>
							<Input type="number" name="kontak" id="kontak" onChange={handleData} />
						</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<Label for="kategori-penjual">Kategori Penjual</Label>
			        <Input type="select" name="kategoriPenjual" id="kategori-penjual" onChange={handleData} >
			          	<option value={null}>Penjual Retail</option>
			          	<option value="Penjual Retail">Penjual Retail</option>
			          	<option value="Penjual Online">Penjual Online</option>
			          	<option value="Penjual Afiliasi">Penjual Afiliasi</option>
			        </Input>
				</FormGroup>
				<Row>
					<Col xs="4">
						<FormGroup>
							<Label for="jumlah">Jumlah</Label>
							<Input type="number" name="jumlah" id="jumlah" onChange={handleData} />
						</FormGroup>
					</Col>
					<Col xs="8">
						<FormGroup>
							<Label for="h-t-penjualan">Harga Total Penjualan</Label>
							<Input type="number" name="hargaTotalPenjualan" id="h-t-penjualan" onChange={handleData} />
						</FormGroup>
					</Col>
				</Row>
	      		<FormGroup>
	        		<Label for="uang-diterima">Uang diterima</Label>
	        		<Input type="number" name="uangDiterima" id="uang-diterima" onChange={handleData} />
	      		</FormGroup>
	      		<FormGroup>
	        		<Label for="uang-dikembalikan">Uang kembalian</Label>
	        		<Input type="number" name="uangDikembalikan" id="uang-dikembalikan" onChange={handleData} />
	      		</FormGroup>
			</Container>
			<div className='space'></div>
	  		<button className="btn be-footer btn-success btn-block" onClick={e=>handleSubmit(e)}>TAMBAH PENJUALAN</button>
    	</div>
	)	
}
export default TambahPenjualan;