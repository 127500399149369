import { Fragment, useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Search from '../../../component/Search/Search';
import { Context } from '../../../App';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

const fakeDb = [
		{id:0, text:['Agus','089134334223']},
		{id:1, text:['Ade','089145634223']}, 
		{id:2, text:['Hera','089345334223']}, 
		{id:3, text:['Indu','089165334223']}
	]

const DataMitra = () => {
	let { dispatch } = useContext(Context)
	let [ section2style, setSection2Style ] = useState({});

	let handleKontakHubungi = (contact) => {
		alert(`menghubungi kontak ${contact[1]}`);
	}
	ChangeTitle({title:'Data Mitra'})
	useEffect(() => {
		let section2height = window.innerHeight-document.getElementsByTagName('section')[1].getBoundingClientRect().top
		setSection2Style({padding:'15px 0', margin:'10px 10px', minHeight:String(section2height)+'px'})
	}, [])
	useEffect(()=>{
		dispatch({type:"TOGGLE_BOTTOM_NAV"})
		return () => {
			dispatch({type:"TOGGLE_BOTTOM_NAV"})
		}
		// eslint-disable-next-line 
	}, [])
	return(
		<Fragment>
			<div style={{background:'white'}}>
				<section style={{padding:'15px 0', marginBottom:'10px'}}>
					<h4 className="text-center">Data Mitra</h4>
					<hr/>
				</section>

				<section style={section2style}>
					<Container>
						<Search filterClick={()=>alert('click')}/>
						{fakeDb.map(contact=>{
							return <DataCustomerhistory key={contact.id} text={contact.text} hubungi={()=>handleKontakHubungi(contact.text)}/>
						})}
					</Container>
				</section>
			</div>
		</Fragment>
	)
}
export default DataMitra;

const DataCustomerhistory = ({text, hubungi}) => {
	return(
		<Row className="ml-2 mr-2 pb-2 mt-3">
			<Col xs="8">
				<strong>{text[0]}</strong>
				<p style={{fontSize:'.8em',lineHeight:'.8em'}} className="text-muted">{text[1]}</p>
			</Col>
			<Col xs="4" className="mt-1">
				<button onClick={hubungi} className="btn btn-success btn-sm btn-block" style={{borderRadius:'1em'}}>hubungi</button>
			</Col>
			<hr className="w-100 my-1"/>
		</Row>
	)
}