import { Fragment, useState } from 'react';
import { Container, Row, Col, Form, Label, Input } from 'reactstrap';
import Search from '../../../component/Search/Search';
import { LaporanKeuanganHistory } from '../../../component/History/History';
import ChangeTitle from '../../../component/ChangeTitle/ChangeTitle';

let historys = [
	{ id:0, text:['14 April 2021', 'penjualan retail', 250000]},
	{ id:1, text:['10 April 2021', 'penjualan event', 2250000]},
	{ id:2, text:['3 April 2021', 'penjualan retail', 590000]},
]

const LaporanKeuangan = () => {
	let [ state, setState ] = useState({
		formData: {},
	})
	let handleData = e => {
		setState({
			...state,
			formData:{
				...state.formData,
				[e.target.name]:e.target.value
			}
		})
	}
	ChangeTitle({title:'Laporan Penjualan'})
	return(
		<Fragment>

			<section style={{ background:'#fff', padding:'15px 0 1px', borderRadius:'0 0 25px 25px', marginBottom:'10px'}}>
				<h4 className="text-center">Laporan Keuangan</h4>
				<hr/>
				<Container>
					<Row>
						<Col xs='12'>
							<p>Periode</p>
						</Col>
						<Col xs='12'>
							<Form inline className='clearfix'>
					            <Input className='float-left' style={{width:'40%'}} bsSize='sm' type="date" name="tanggalStart" id="tanggalStart" onChange={handleData}/>
					            <Label className='float-left' style={{width:'20%', marginTop:'.3em', textAlign:'center', margin:'0 !important'}} for="tanggalEnd">s/d</Label>
					            <Input className='float-left' style={{width:'40%'}} bsSize='sm' type="date" name="tanggalEnd" id="tanggalEnd" onChange={handleData}/>
					        </Form>
					        <hr className='w-100 mt-2'/>
						</Col>
					</Row>
				</Container>
			</section>

			<section style={{background:'#fff', padding:'15px 0', borderRadius:'25px 25px 25px 25px', height:'100%', margin:'10px 10px'}}>
				<Container>
					<div className="pl-3 mt-3">
						<h3>Rp. 3.923.902.000</h3>
						<p>Total Uang Diterima</p>
					</div>
					<Search />
					{historys.map(history=>{
						return <LaporanKeuanganHistory key={history.id} text={history.text} />
					})}
				</Container>
				<div className="space-h-5"></div>
			</section>
			
		</Fragment>
	)
}
export default LaporanKeuangan;