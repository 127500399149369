import { useState } from 'react';
import Popup from '../../../component/Popup/Popup';
import { FormGroup, Label, Input } from 'reactstrap';

const BuangStok = ({close}) => {
	let [ formData, setFormData ] = useState({ tanggalBuang:'', kodePrduksi:'', jumlahCup:'' })
	let handleData = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}
	let submit = e => {
		e.preventDefault()
		close(formData)
	}
	return (
		<Popup close={close}>
			<div style={{padding:'0 1.2em'}}>
				<h4 className="text-center mb-3">BUANG STOK</h4>
				<FormGroup>
					<Label for="tanggalBuang">Tanggal Buang</Label>
					<Input id="tanggalBuang" name="tanggalBuang" type="date" onChange={handleData}/>
				</FormGroup>
				<FormGroup>
					<Label for="kodePrduksi">Kode Produksi</Label>
					<Input id="kodePrduksi" name="kodePrduksi" type="text" onChange={handleData}/>
				</FormGroup>
				<FormGroup>
					<Label for="jumlahCup">Jumlah Cup</Label>
					<Input id="jumlahCup" name="jumlahCup" type="number" onChange={handleData}/>
				</FormGroup>
				<button className="btn btn-danger w-100" onClick={submit} style={{margin:'2.5em 0 0'}}>BUANG STOK</button>
			</div>
		</Popup>
	)
}

export default BuangStok;