import { useState } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import Popup from '../../../component/Popup/Popup';

const TambahCustomer = ({close}) => {
	let [ formData, setFormData ] = useState({nama:'', noWa:''})
	let handleForm = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}
	let submit = e => {
		e.preventDefault()
		close(formData)
	}
	return (
		<Popup close={close}>
			<div style={{padding:'1em'}}>
				<h5>TAMBAH CUSTOMER</h5>
				<FormGroup>
					<Label to="nama">Nama</Label>
					<Input id="nama" name="nama" type="text" onChange={handleForm}/>
				</FormGroup>
				<FormGroup>
					<Label to="noWa">No. Wa</Label>
					<Input id="noWa" name="noWa" type="number" onChange={handleForm}/>
				</FormGroup>
				<Button block color="success" onClick={submit}>TAMBAH</Button>
			</div>
		</Popup>
	)
}

export default TambahCustomer;